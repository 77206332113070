import React, { useEffect } from "react";
import UserHeader from "../../Header/Header";
import Sidenav from "../../Sidebar/Sidenav";
import "../css/usersPages.css";
import BackupWallet from "./BackupWallet";
import { wordlist } from "./wordlist";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Backup() {
	const { t } = useTranslation();
	const history = useNavigate();
	//Effects
	useEffect(() => {
		if (!localStorage.getItem("SeamR_user")) {
			history("/signin");
		}
	}, []);
	const rand = [];
	for (var i = 0; i < 12; i++) {
		let x = wordlist[Math.floor(Math.random() * (wordlist.length - 1))];
		if (!rand.includes(x)) {
			rand.push(x);
		}
	}
	const rands = rand.toString();
	return (
		<div className="userbg">
			<div className="user_app">
				<UserHeader />
				<Sidenav title={t("userbk1")} />
				<div className="users_modal">
					<BackupWallet rands={rands} />
				</div>
			</div>
		</div>
	);
}

export default Backup;
