import React from 'react';
import SectionTitle from './component/SectionTitle';
import CounterUp from './CounterUp';
import "../css/_counterup.scss"
import AOS from "aos";
import "aos/dist/aos.css";
const CounterUpOne = () => {
    return (
        <div className="section section-padding bg-color-dark">
            <div className="container" data-aos="fade-right">
            <SectionTitle 
                    subtitle="Earn With Ease"
                    title="Eager to mine?"
                    description="Utilize Seamridge to advance your financial situation. Sign up, put money in, and watch it grow."
                    textAlignment="heading-light"
                    textColor=""
                />
                <div className="row" >
                    <CounterUp colSize="col-lg-3 col-6" layoutStyle="" evenTopMargin="" />
                </div>
            </div>
        </div>
    )
}


export default CounterUpOne;