import React from "react";
import CoinItem from "./RenderCoin";
import Coin from "./routing/Coin";
import { Link } from "react-router-dom";
import "./coins.css";
import Nav from "../../Nav/Nav";

//map over the coins
function Coins({ coins }) {
	return (
		<>
			<Nav />
			<div className="container coinContainer">
				<div className="coinHead flex flex-row justify-between items-center bg-gray-900 rounded-lg font-bold mx-15 my-8 py-3 px-4 shadow-sm">
					<p>#</p>
					<p className="-ml-16">Coin</p>
					<p>Price</p>
					<p>24h</p>
					<p className="hide-mobile">Volume</p>
					<p className="hide-mobile">Mkt Cap</p>
				</div>
				{coins.map((coin) => {
					return (
						<Link element={<Coin />} key={coin.id}>
							<CoinItem coin={coin} />
						</Link>
					);
				})}
			</div>
		</>
	);
}

export default Coins;
