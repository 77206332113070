import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminDash from "./Components/Admin/admin";
import FundsR from "./Components/Admin/Funds/fundsR";
import TransferR from "./Components/Admin/Transfer/transferR";
import UsersR from "./Components/Admin/Users/usersR";
import UsersP from "./Components/Admin/Users/usersP.js";
import WithdrawR from "./Components/Admin/Withdraw/withdrawR";
import Admin from "./Components/Auth/admin/admin";
import Forgot from "./Components/Auth/forgot/Forgot";
import Reset from "./Components/Auth/forgot/Reset";
import Login from "./Components/Auth/login/login";
import Registeration from "./Components/Auth/registration/reg";
import Home from "./Components/Home/Home";
import Nav from "./Components/Nav/Nav";
import About from "./Components/Pages/about/about";
import Contact from "./Components/Pages/contact/contact";
import Error from "./Components/Pages/Error/Error";
import Coins from "./Components/Pages/market/Coins";
import Account from "./Components/UserApp/Pages/AccountManager/Account";
import Backup from "./Components/UserApp/Pages/Backup/Backup";
import Fund from "./Components/UserApp/Pages/Fund/Fund";
import Pension from "./Components/UserApp/Pages/Pension/Pension";
import Referrals from "./Components/UserApp/Pages/Referrals/Referrals";
import Support from "./Components/UserApp/Pages/Support/Support";
import Transfer from "./Components/UserApp/Pages/Transfer/Transfer";
import Withdraw from "./Components/UserApp/Pages/Withdraw/Withdraw";
import Index from "./Components/UserApp/UserApp";
import ScrollToTop from "./ScrollToTop";
export default function App() {
	const [coins, setCoins] = useState([]);

	const url =
		"https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false";
	useEffect(() => {
		fetch(url)
			.then((res) => res.json())
			.then((data) => setCoins(data))
			.catch((error) => {});
	}, [url]);
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route exact path="contact" element={<Contact />} />
				<Route exact path="app" element={<Index />} />
				<Route exact path="app/fund" element={<Fund />} />
				<Route exact path="app/withdraw" element={<Withdraw />} />
				<Route exact path="app/transfer" element={<Transfer />} />
				<Route exact path="app/account-manager" element={<Account />} />
				<Route exact path="app/backup-wallet" element={<Backup />} />
				<Route exact path="app/referrals" element={<Referrals />} />
				<Route exact path="app/pension" element={<Pension />} /> 
				<Route exact path="about" element={<About />} />
				<Route exact path="signup" element={<Registeration />} />
				<Route exact path="signin" element={<Login />} />
				<Route exact path="forgot" element={<Forgot />} />
				<Route exact path="reset" element={<Reset />} />
				<Route exact path="/app/support" element={<Support />} />
				<Route exact path="/user-admin-control" element={<Admin />} />
				<Route exact path="/adminzdash" element={<AdminDash />} />
				<Route exact path="/adminzdash/fund" element={<FundsR />} />
				<Route exact path="/adminzdash/withdraw" element={<WithdrawR />} />
				<Route exact path="/adminzdash/transfer" element={<TransferR />} />
				<Route exact path="/adminzdash/users" element={<UsersR />} />
				<Route exact path="/adminzdash/users/:id" element={<UsersP />} />
				<Route exact path="market" element={<Coins coins={coins} />} />
				<Route path="*" element={<Error />} />
			</Routes>
		</BrowserRouter>
	);
}
