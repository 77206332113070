import "./style.css";
import { PopupMenu } from "react-simple-widgets";
import drop from "./drop.png";
import log from "./log.png";
import { useTranslation } from "react-i18next";
export default function Dropdown() {
	const { t } = useTranslation();
	function logoutbtn() {
		localStorage.removeItem("SeamR_user");
		window.location.reload();
	}
	return (
		<div id="app">
			<div className="text-end">
				<PopupMenu>
					<div>
						<img src={drop} alt="dropdown" />
					</div>
					<div className="text-start">
						<button className="btn audibtn btn-secondary" onClick={logoutbtn}>
							<img className="logoutBtn" src={log} alt="user" />
							{t("logout")}
						</button>
					</div>
				</PopupMenu>
			</div>
		</div>
	);
}
