import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
function ItemsR({ userid, name, email, country, token }) {
	return (
		<>
			<div key={userid} className="itemsR">
				Name: <span>{name}</span>
				Email:<span>{email}</span>
				Country:<span>{country}</span>
				<span>
					<Link
						className="users_btn Rbtn"
						exact="true"
						to={"/adminzdash/users/" + userid}
						state={{ name, userid, email, country, token }}
					>
						Select User
					</Link>
				</span>
			</div>
		</>
	);
}

export default ItemsR;
