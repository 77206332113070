import React, { useState, useMemo, useEffect } from "react";
import Footer from "../../Footer/Footer";
import { Navigate } from "react-router-dom";
import "../css/reg.css";
import Select from "react-select";
import countryList from "react-select-country-list";
import Nav from "../../Nav/Nav";
import img from "./img.png";
import { useTranslation } from "react-i18next";
function Registeration() {
	const { t } = useTranslation();
	const [name, setName] = useState(null);
	const [email, setEmail] = useState(null);
	const [eye, seteye] = useState(true);
	const [redirctTo, setRedirctTo] = useState(false);
	const [password, setpassword] = useState("password");
	const [spassword, setspassword] = useState("password");
	const [type, settype] = useState(true);
	const [country, setCountry] = useState("");
	const [error, setError] = React.useState("");
	const options = useMemo(() => countryList().getData(), []);
	useEffect(() => {
		if (localStorage.getItem("SeamR_user")) {
			setRedirctTo(true);
		}
	}, []);
	const handleInputChange = (e) => {
		const { name, value, type } = e.target;
		if (name == "name") {
			setName(value);
		}
		if (name == "email") {
			setEmail(value);
		}
		if (name == "password") {
			setpassword(value);
		}
		if (name == "spassword") {
			setspassword(value);
		}
	};
	const onSubmit = async (e) => {
		e.preventDefault();
		let result = await fetch("https://seamridge.com/backend/public/api/register", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				name,
				email,
				password,
				country: country,
				password_confirmation: spassword,
			}),
		});
		result = await result.json();
		if (result.token) {
			localStorage.setItem("SeamR_user", JSON.stringify(result));
			setRedirctTo(true);
		} else {
			localStorage.setItem("SeamR_error", JSON.stringify(result));
			setError(
				result.errors.email ||
					result.errors.password ||
					result.errors.country ||
					result.errors.name
			);
		}
	};
	const Eye = () => {
		seteye(!eye);
	};

	const changeHandler = (value) => {
		const country = value.label;
		setCountry(country);
	};
	const Type = () => {
		settype(!type);
	};
	if (redirctTo) {
		return <Navigate to="/app" />;
	}
	return (
		<>
			<Nav />
			<section className="contact-section">
				<div className="container">
					<div className="row">
						<div className="col-md-6 contactf">
							<div className="contact-form reg-form">
								<h1>{t("HSignup")}</h1>
								<p>{t("S1")}</p>
								<form onSubmit={onSubmit} method="post" className="row">
									<div className="col-md-10">
									{t("name")}
										<input
											type="text"
											name="name"
											placeholder={t("nameText")}
											onChange={(e) => handleInputChange(e)}
										/>
									</div>
									<div className="col-md-10">
									{t("email")}
										<input
											type="email"
											name="email"
											placeholder={t("emailText")}
											onChange={(e) => handleInputChange(e)}
										/>
									</div>
									<div className="pad-b col-md-10">
									{t("country")}
										<Select
											placeholder={t("countryText")}
											options={options}
											onChange={changeHandler}
										/>
									</div>

									{/* <div className="col-md-10">
										<input
											type={password}
											className="warning"
											placeholder="Enter your password"
											value={password}
											onChange={(e) => handleInputChange(e)}
											name="password"
										/>
										
									</div> */}
									<div className="col-md-10">
									{t("password")}
										<input
											type={eye ? "password" : "text"}
											name="password"
											placeholder={t("passwordText")}
											onChange={(e) => handleInputChange(e)}
										/>
										<i
											onClick={Eye}
											className={`fa form_eye ${
												eye ? "fa-eye-slash" : "fa-eye"
											}`}
										></i>
									</div>
									<div className="col-md-10">
									{t("confirmP")}
										<input
											type={type ? "password" : "text"}
											name="spassword"
											placeholder={t("confirmPText")}
											onChange={(e) => handleInputChange(e)}
										/>
										<i
											onClick={Type}
											className={`fa form_eye ${
												type ? "fa-eye-slash" : "fa-eye"
											}`}
										></i>
									</div>
									{{ error } && (
										<div className="container">
											<h5
												style={{
													color: "red",
													paddingBottom: "20px",
													width: "104%",
													fontWeight: "bold",
												}}
											>
												<br />
												{error}
											</h5>
										</div>
									)}
									<div className="contact-btn">
										<input
											type="submit"
											name="submit"
											className="col-md-10"
											value={t("HSignup")}
										/>
									</div>
									<div className="orDiv col-md-10">
										<span className="rightLn"></span>
										<span className="midtxt">{t("or")}</span>
										<span className="leftLn"></span>
									</div>
									<div className="contact-btn">
										<input
											type="submit"
											name="submit"
											className="col-md-10 contact-white"
											value={t("login")}
											onClick={(event) => (window.location.href = "/signin")}
										/>
									</div>
								</form>
							</div>
						</div>
						<div className="col-md-6 authwindView">
							<div className="authimg regimg">
								<img src={img} alt="Img" width={"100%"} />
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Registeration;
