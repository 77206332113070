import React, { useEffect } from "react";
import "../css/about.css";
import about1 from "./about1.jpg";
import about2 from "./about2.jpg";
import about3 from "./about3.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function AboutDetails() {
	const { t} = useTranslation();
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	return (
		<div className="container about">
			<div className="single-content" data-aos="fade-right">
				<div className="about-txt">
					<h1>{t("AWhotitle")}</h1>
					<p>{t("AWhoSubtitle")}</p>
				</div>
				<div className="about-img">
					<img src={about1} alt="who we are" />
				</div>
			</div>
			<div className="windowt single-content mobile-right" data-aos="fade-left">
				<div className="about-img">
					<img src={about2} alt="Our goal" />
				</div>
				<div className="about-txt">
					<h1>{t("AGoaltitle")}</h1>
					<p>{t("AGoalSubtitle")}</p>
				</div>
			</div>
			<div className="single-content mobile-right mobile" data-aos="fade-left">
				<div className="about-txt">
					<h1>{t("AGoaltitle")}</h1>
					<p>{t("AGoalSubtitle")}</p>
				</div>
				<div className="about-img">
					<img src={about2} alt="Our goal" />
				</div>
			</div>
			<div className="single-content" data-aos="fade-right">
				<div className="about-txt">
					<h1>{t("AMissiontitle")}</h1>
					<p>{t("AMissionSubtitle")}</p>
				</div>
				<div className="about-img">
					<img src={about3} alt="Our mission" />
				</div>
			</div>
		</div>
	);
}

export default AboutDetails;
