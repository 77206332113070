import React, { useEffect, useState } from "react";
import "../css/about.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function AboutTeam() {
	const [show, setshow] = useState(false);
	const handleChange = (e) => {
		setshow(true);
		setTimeout(() => {
			setshow(false);
		}, 3000);
	};
	const { t } = useTranslation();
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	return (
		<div className="container abt_team">
			<div className="about_team" data-aos="fade-right">
				<h1>{t("AJoinH")}</h1>
				<p>{t("AJoin")}</p>
				<a class="main-btn ml-30" onClick={handleChange}>
					{t("AJoinBtn")}
				</a>
				{show && (
					<h2 style={{ marginTop: "40px" }}>
						No Open Roles Yet Please Check Back Later
					</h2>
				)}
			</div>
		</div>
	);
}

export default AboutTeam;
