import React, { useEffect, useState } from "react";
import axios from "axios";
function ItemsP({ id, user, plan, amount, token, type, accept, description }) {
	const onAccept = () => {
		let axiosConfig = {
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		};
		let request = {
			accept: 1,
		};
		axios
			.put(
				`https://seamridge.com/backend/public/api/products/${id}`,
				request,
				axiosConfig
			)
			.then((res) => {
				if (res) {
					window.location.reload();
				}
			})
			.catch((err) => {});

		// window.location.reload();
	};
	const onReject = () => {
		let axiosConfig = {
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		};
		axios
			.delete(
				`https://seamridge.com/backend/public/api/products/${id}`,
				axiosConfig
			)
			.then((res) => {
				if (res) {
					window.location.reload();
				}
			})
			.catch((err) => {});
	};
	return (
		<>
			<div key={id} className="itemsR">
				{type == "fund" || type == "invest" ? (
					<div>
						<span>
							<h2>{type} for</h2>
						</span>
						<span>{plan} Plan</span> amount: <span>${amount}</span>
					</div>
				) : (
					<div>
						<span>
							<h2>{type} </h2>
						</span>
						<span>
							{description == "Funds Transfer"
								? "with username"
								: "with Wallet Address:"}{" "}
							{plan}
						</span>{" "}
						amount: <span>${amount}</span>
					</div>
				)}
				{accept != 1 ? (
					<div>
						<span>
							<button className="users_btn Rbtn" onClick={onAccept}>
								Accept Request
							</button>
						</span>
						<span>
							<button className="users_btn Rbtn red" onClick={onReject}>
								Delete Request
							</button>
						</span>
					</div>
				) : (
					<span>
						<button className="users_btn Rbtn red" onClick={onReject}>
							Delete Transaction
						</button>
					</span>
				)}
			</div>
		</>
	);
}

export default ItemsP;
