import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Navigation() {
	const { t, i18n } = useTranslation();
	return (
		<>
			<ul>
				<li>
					<Link to="/market">{t("footL1")}</Link>
				</li>
				<li>
					<a href="/#plans">{t("Plans")}</a>
				</li>
				<li>
					<Link to="/about">{t("footL2")}</Link>
				</li>
				<li>
					<Link to="/contact">{t("Contact")}</Link>
				</li>
			</ul>
		</>
	);
}

export default Navigation;
