import React from "react";
// import "./css/hero.css";
import dash from "./images/dash.png";
import fundw from "./images/fundw.png";
import withdraw from "./images/withdraw.png";
import market from "./images/market.png";
import history from "./images/history.png";
import transferf from "./images/transferf.png";
import pension from "./images/pension.png";
import backupw from "./images/backupw.png";
import referrals from "./images/referrals.png";
import accountm from "./images/accountm.png";
import help from "./images/help.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Sidebar({ openClass, setTrig, on, setOn }) {
	const { t } = useTranslation();
	//handling
	const handleOne = () => {
		setTrig(false);
		if (on == true) {
			setOn(!on);
		}
	};
	const handleTwo = () => {
		setTrig(true);
		if (on == true) {
			setOn(!on);
		}
	};
	return (
		<nav className={`wnav ${openClass == "open" ? "opneSidebar" : "wnav"}`}>
			<div className="txt">ADMIN SYSTEM</div>
			<div className="border"></div>
			<ul className="navlist">
				<li>
					<Link className="menu-item" exact="true" to="/adminzdash">
						<img src={dash} alt="fundw" />
						Dashboard
					</Link>
				</li>
				<li>
					<Link className="menu-item" exact="true" to="/adminzdash/fund">
						<img src={fundw} alt="fundw" />
						Funds Pension & Invest Requests
					</Link>
				</li>
				<li>
					<Link className="menu-item" exact="true" to="/adminzdash/withdraw">
						<img src={withdraw} alt="withdraw" />
						Withdraw Requests
					</Link>
				</li>
				<li>
					<Link className="menu-item" exact="true" to="/adminzdash/transfer">
						<img src={transferf} alt="transferf" />
						Transfer Requests
					</Link>
				</li>
				<li>
					<Link className="menu-item" exact="true" to="/adminzdash/users">
						<img src={referrals} alt="users" />
						Users
					</Link>
				</li>
			</ul>
		</nav>
	);
}
export default Sidebar;
