import React from "react";
import "../css/forgot.css";
import { Link } from "react-router-dom";
import axios from "axios";
import useForm from "./Hooks/useForm";
import Nav from "../../Nav/Nav";
import { useTranslation } from "react-i18next";
const Forgot = () => {
	const { t } = useTranslation();
	const [uphrase, setUPhrase] = React.useState("");
	const [dphrase, setDPhrase] = React.useState("");
	const [phrase, setphrase] = React.useState([]);
	const [msg, setMsg] = React.useState([]);
	const [send, setSend] = React.useState(false);
	const [phraseValues, phraseFormChange] = useForm({
		name: "phrase",
		slug: "",
		price: "unknown",
		description: "",
	});

	React.useEffect(() => {
		async function fetchMyAPI() {
			try {
				let result = await fetch("https://seamridge.com/backend/public/api/products");
				result = await result.json();
				const mappp = result.filter((item) => item.name == "phrase");
				setDPhrase(mappp);
			} catch (err) {}
		}

		fetchMyAPI() && fetchMyAPI();
	}, []);

	function handlephrase(e) {
		e.preventDefault();
		const phrase = e.target[0].value;
		setUPhrase(phrase);
		const mappp = dphrase.filter(
			(item) => item.description == phraseValues.description
		);
		setphrase(mappp);
	}

	function handleMail(e) {
		setSend(true);
		const mail = phrase[0].slug;
		axios
			.post("https://seamridge.com/backend/public/api/forgot-password", {
				email: mail,
			})
			.then(function (response) {
				setMsg(response.data.status);
			})
			.catch(function (error) {});
	}
	return (
		<>
			<Nav />
			<div className="container">
				{phrase.length == 0 && msg.length == 0 ? (
					<div>
						<div className="forgot_cont">
							<h2>{t("forgot1")}</h2>
							<div className="phrase_red">
								{uphrase && "incorrect phrase, please fill your correct phrase"}{" "}
							</div>
							<div className="alert-box warning">{t("forgot2")}</div>
							<h4>{t("forgot3")}</h4>
							<form onSubmit={handlephrase}>
								<textarea
									name="description"
									value={phraseValues.description}
									onChange={phraseFormChange}
									className="forg"
									placeholder={t("forgot4")}
								/>
								<div className="withdraw_btn forgot_btn">
									<button>{t("forgotBtn")}</button>
								</div>
							</form>
						</div>
						<div
							className="forgot_btn"
							style={{ marginTop: "-50px", color: "red !important" }}
						></div>
					</div>
				) : (
					<div>
						<div className="forgot_cont">
							<h2>{t("forgot5")}</h2>
							<div
								style={{ margin: "40px" }}
								className={msg.length == 0 ? "forget_info" : "hide"}
							>
								{t("forgot6")}{" "}
								<b>
									{phrase.map((item) => {
										return item.slug;
									})}
								</b>
								{t("forgot7")}
							</div>
							{msg.length !== 0 && (
								<div className="f_sucess">
									{msg}
									{t("forgot8")}
								</div>
							)}
							<div className="forgot_btn">
								{msg.length !== 0 ? (
									<Link to="/auth">
										<button>{t("back")}</button>
									</Link>
								) : send ? (
									<button disabled style={{ opacity: "0.7" }}>
										{t("forgot5")}
									</button>
								) : (
									<button onClick={handleMail}>{t("forgot5")}</button>
								)}
							</div>
						</div>
						<div
							className=" forgot_btn"
							style={{ marginTop: "-50px", color: "red !important" }}
						></div>
					</div>
				)}
			</div>
		</>
	);
};
export default Forgot;
