import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../css/features.css";
import featuresOne from "./features1.jpg";
import featuresTwo from "./features2.jpg";
import featuresThree from "./features3.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function Features({ className }) {
	const { t } = useTranslation();
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	const [tab, setTab] = useState("setting");
	const handleClick = (e, value) => {
		e.preventDefault();
		setTab(value);
	};
	return (
		<section
			className={`appie-features-area pt-100 ${className}`}
			id="features"
		>
			<div className="container">
				<div className="row align-items-center features-cont">
					<div className="col-lg-5" data-aos="fade-right">
						<h1>{t("Ftitle")}</h1>
						<div className="appie-features-tabs-btn">
							<div
								className="nav flex-column nav-pills"
								id="v-pills-tab"
								role="tablist"
								aria-orientation="vertical"
							>
								<a
									onClick={(e) => handleClick(e, "setting")}
									className={`nav-link ${tab == "setting" ? "active" : ""}`}
									id="v-pills-home-tab"
									data-toggle="pill"
									href="#v-pills-home"
									role="tab"
									aria-controls="v-pills-home"
									aria-selected="true"
								>
									{t("FSTitle")}
								</a>
								<p className={` ${tab == "setting" ? "active" : ""}`}>
								{t("FSsubTitle")}
								</p>
								<div
									className={`fearures_mobile ${
										tab == "setting" ? "show active mob" : ""
									} tab-pane fade`}
									id="v-pills-home"
									role="tabpanel"
									aria-labelledby="v-pills-home-tab"
								>
									<div className="row align-items-center" data-aos="fade-left">
										<div className="col-lg-6">
											<div
												className="appie-features-thumb text-center wow animated fadeInUp"
												data-wow-duration="2000ms"
												data-wow-delay="200ms"
											>
												<img src={featuresOne} alt="" />
											</div>
										</div>
									</div>
								</div>

								<a
									onClick={(e) => handleClick(e, "report")}
									className={`nav-link ${tab == "report" ? "active" : ""}`}
									id="v-pills-profile-tab"
									data-toggle="pill"
									href="#v-pills-profile"
									role="tab"
									aria-controls="v-pills-profile"
									aria-selected="false"
								>
									{t("FDTitle")}
								</a>
								<p className={` ${tab == "report" ? "active" : ""}`}>
								{t("FDsubTitle")}
								</p>
								<div
									className={`fearures_mobile ${
										tab == "report" ? "show active mob" : ""
									} tab-pane fade`}
									id="v-pills-home"
									role="tabpanel"
									aria-labelledby="v-pills-home-tab"
								>
									<div className="row align-items-center" data-aos="fade-left">
										<div className="col-lg-6">
											<div
												className="appie-features-thumb text-center wow animated fadeInUp"
												data-wow-duration="2000ms"
												data-wow-delay="200ms"
											>
												<img src={featuresTwo} alt="" />
											</div>
										</div>
									</div>
								</div>
								<a
									onClick={(e) => handleClick(e, "notice")}
									className={`nav-link ${tab == "notice" ? "active" : ""}`}
									id="v-pills-messages-tab"
									data-toggle="pill"
									href="#v-pills-messages"
									role="tab"
									aria-controls="v-pills-messages"
									aria-selected="false"
								>
									{t("FITitle")}
								</a>
								<p className={` ${tab == "notice" ? "active" : ""}`}>
								{t("FIsubTitle")}
								</p>
								{/* <a
									onClick={(e) => handleClick(e, "app")}
									className={`nav-link ${tab == "app" ? "active" : ""}`}
									id="v-pills-settings-tab"
									data-toggle="pill"
									href="#v-pills-settings"
									role="tab"
									aria-controls="v-pills-settings"
									aria-selected="false"
								>
									<i className="fas fa-lock" /> App Lock
								</a> */}
							</div>
						</div>
					</div>
					<div className="col-lg-7">
						<div className="tab-content" id="v-pills-tabContent">
							<div
								className={`features_windows ${
									tab == "setting" ? "show active" : ""
								} tab-pane fade`}
								id="v-pills-home"
								role="tabpanel"
								aria-labelledby="v-pills-home-tab"
							>
								<div className="row align-items-center" data-aos="fade-left">
									<div className="col-lg-6">
										<div
											className="appie-features-thumb text-center wow animated fadeInUp"
											data-wow-duration="2000ms"
											data-wow-delay="200ms"
										>
											<img src={featuresOne} alt="" />
										</div>
									</div>
								</div>
							</div>
							<div
								className={`features_windows ${
									tab == "report" ? "show active" : ""
								} tab-pane fade`}
								id="v-pills-profile"
								role="tabpanel"
								aria-labelledby="v-pills-profile-tab"
							>
								<div className="row align-items-center" data-aos="fade-left">
									<div className="col-lg-6">
										<div
											className="appie-features-thumb text-center animated fadeInUp"
											data-wow-duration="2000ms"
											data-wow-delay="200ms"
										>
											<img src={featuresTwo} alt="" />
										</div>
									</div>
								</div>
							</div>
							<div
								className={`${
									tab == "notice" ? "show active" : ""
								} tab-pane fade`}
								id="v-pills-messages"
								role="tabpanel"
								aria-labelledby="v-pills-messages-tab"
							>
								<div className="row align-items-center" data-aos="fade-left">
									<div className="col-lg-6">
										<div
											className="appie-features-thumb text-center animated fadeInUp"
											data-wow-duration="2000ms"
											data-wow-delay="200ms"
										>
											<img src={featuresThree} alt="" />
										</div>
									</div>
								</div>
							</div>
							{/* <div
								className={`${
									tab == "app" ? "show active" : ""
								} tab-pane fade`}
								id="v-pills-settings"
								role="tabpanel"
								aria-labelledby="v-pills-settings-tab"
							>
								<div className="row align-items-center">
									<div className="col-lg-6">
										<div
											className="appie-features-thumb text-center animated fadeInUp"
											data-wow-duration="2000ms"
											data-wow-delay="200ms"
										>
											<img src={thumb} alt="" />
                                            thumb
										</div>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Features;
