import React from "react";
import "../css/error.css";
export default function Error() {
	return (
		<>
			<div className="error-container">
				<h1 className="error-title">404</h1>
				<h3 className="error-sub-title">Oopsie! Somthing's missing</h3>
				<p className="error-descp">
					The page you are looking for does not exist or has been moved
				</p>
				<button className="back-to-home-btn"> <a href="/">
										Back Home
									</a> </button>
			</div>
		</>
	);
}
