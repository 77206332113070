import React, { useEffect } from "react";
import "../css/about.css";
import Fwidget from "./widget";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function Aboutservices() {
	const { t } = useTranslation();
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	return (
		<div className="container about-services">
			<h1 data-aos="fade-down">{t("what")}</h1>
			<div className="a-services">
				<div className="single-a-services" data-aos="fade-right">
					<div className="single-a-elipse">
						<p>1</p>
						<h3>{t("ABtitle")}</h3>
						<p className="single-a-body">{t("ABSubtitle")}</p>
					</div>
				</div>
				<div className="single-a-services service-two" data-aos="fade-right">
					<div className="single-a-elipse">
						<p>2</p>
						<h3>{t("AAtitle")}</h3>
						<p className="single-a-body">{t("AASubtitle")}</p>
					</div>
				</div>
				<div className="single-a-services service-three" data-aos="fade-right">
					<div className="single-a-elipse">
						<p>3</p>
						<h3>{t("ACtitle")}</h3>
						<p className="single-a-body">{t("ACSubtitle")}</p>
					</div>
				</div>
				<div className="single-a-services service-two" data-aos="fade-left">
					<div className="single-a-elipse">
						<p>4</p>
						<h3>{t("AGtitle")}</h3>
						<p className="single-a-body">{t("AGSubtitle")}</p>
					</div>
				</div>
				<div className="single-a-services" data-aos="fade-left">
					<div className="single-a-elipse">
						<p>5</p>
						<h3>{t("AOtitle")}</h3>
						<p className="single-a-body">{t("AOSubtitle")}</p>
					</div>
				</div>
			</div>
			<Fwidget />
		</div>
	);
}

export default Aboutservices;
