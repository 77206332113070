import React, { useEffect, useState } from "react";
import "../css/fund.css";
import sucess from "./cryp/sucess.png";
import axios from "axios";
import { useTranslation } from "react-i18next";
function UserWithdraw() {
	const { t } = useTranslation();
	const [wallet, setwallet] = useState("");
	const [pmethod, setpmethod] = useState("");
	const [amount, setamount] = useState("");
	const [formE, setformE] = useState(false);
	const [formNext, setformNext] = useState(false);
	const [token, settoken] = useState("");
	const [usersbal, setusersbal] = useState("");
	const [usersname, setusersname] = useState("");
	//Life Cycle Users
	useEffect(() => {
		if (localStorage.getItem("SeamR_user")) {
			settoken(JSON.parse(localStorage.getItem("SeamR_user")).token);
			setusersname(JSON.parse(localStorage.getItem("SeamR_user")).user.name);
		}
	}, []);
	//End LF
	const optionsFund = (e) => {
		const { name, value } = e.target;
		if (name == "wallet") {
			setwallet(value);
		}
		if (name == "method") {
			setpmethod(value);
		}
		if (name == "amount") {
			setamount(value);
		}
	};
	const onSubmit = (e) => {
		e.preventDefault();
		//Rules
		if (amount == "" || pmethod == "" || (amount && pmethod == "")) {
			setformE(true);
			setTimeout(() => {
				setformE(false);
			}, 2000);
		} else {
			setformE(false);
			setformNext(true);
			let result = {
				name: "withdraw",
				slug: wallet,
				description: "withdrawal",
				price: amount + " " + pmethod,
				username: usersname,
				usertoken: token,
				accept: 1,
			};
			let axiosConfig = {
				headers: {
					"Content-Type": "application/json;charset=UTF-8",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
			};
			axios
				.post(
					"https://seamridge.com/backend/public/api/products",
					result,
					axiosConfig
				)
				.then((res) => {})
				.catch((err) => {});
		}
	};
	//Life Cycle
	useEffect(() => {
		if (localStorage.getItem("SeamR_users")) {
			setusersbal(JSON.parse(localStorage.getItem("SeamR_users")));
		}
	}, []);
	//End LF
	const withdrawable_bal = usersbal && usersbal.withdrawable;
	return (
		<>
			{formNext ? (
				<div>
					<div className="user_fund user_with">
						<p>{t("userw6")}</p>
						<img className="cryp_img" src={sucess} alt="crypto" />
						<h2>{t("userw7")}</h2>
						<h6>{t("userw8")}</h6>
					</div>
					<span className="users_btn with_btn">
						<a href="/app">{t("ok")}</a>
					</span>
				</div>
			) : (
				<div className="user_fund">
					<p>{t("userw2")}</p>
					<h3>{"$ " + withdrawable_bal.toLocaleString()}</h3>
					{formE && <p className="abso color_red">{t("complete")}</p>}
					<form onSubmit={onSubmit}>
						<span className="users_drop">
							<input
								placeholder={t("userw3")}
								onChange={optionsFund}
								name="amount"
								type="number"
								min="1"
								max={withdrawable_bal}
							/>
						</span>
						<span className="users_drop">
							<select value={pmethod} onChange={optionsFund} name="method">
								<option value="">{t("userw4")}</option>
								<option value="BITCOIN">BITCOIN</option>
								<option value="ETHEREUM">ETHEREUM</option>
								<option value="USDT">USDT</option>
							</select>
						</span>
						<span className="users_drop">
							<input
								placeholder={t("userw5")}
								onChange={optionsFund}
								name="wallet"
								type="text"
							/>
						</span>
						<span className="users_drop user_submit">
							<input type="submit" value={t("Submit")} />
						</span>
					</form>
				</div>
			)}
		</>
	);
}

export default UserWithdraw;
