import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import axios from "axios";
import Nav from "../../Nav/Nav";
import "../css/pstyles.css";
import sucess from "./success.png";
import { useTranslation } from "react-i18next";
const Reset = () => {
	const { t } = useTranslation();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const token = searchParams.get("token");
	const [value1, setValue1] = React.useState("");
	const [value2, setValue2] = React.useState("");
	const [value3, setValue3] = React.useState("");
	const [redirctTo, setRedirctTo] = React.useState(false);
	const [msg, setMsg] = React.useState([]);
	const [error, setError] = React.useState(false);
	function handleChange1(event) {
		event.preventDefault();
		setValue1(event.target.value);
	}
	function handleChange2(event) {
		event.preventDefault();
		setValue2(event.target.value);
	}
	function handleChange3(event) {
		event.preventDefault();
		setValue3(event.target.value);
	}

	function onsub(event) {
		event.preventDefault();
		const data = {
			email: value3,
			password: value1,
			password_confirmation: value2,
			token: token,
		};
		axios
			.post("https://seamridge.com/backend/public/api/reset-password", data)
			.then(function (response) {
				setMsg(response.data.message);
			})
			.catch(function (error) {});
		setError(true);
	}

	let colour1 = "red",
		colour2 = "red",
		colour3 = "red",
		colour4 = "red",
		colour5 = "red";
	if (value1.length >= "8") {
		colour1 = "green";
	}
	if (value1.match(/[A-Z]/)) {
		colour2 = "green";
	}
	if (value1.match(/[a-z]/)) {
		colour3 = "green";
	}
	if (value1.match(/[\d`~!@#$%&*()+=|;:'",.<>?\\]/)) {
		colour4 = "green";
	}
	if (value1 == value2 && value1 !== "") {
		colour5 = "green";
	}
	React.useEffect(() => {
		token == null && setRedirctTo(true);
	}, []);
	if (redirctTo) {
		return <Navigate to="/app" />;
	}
	return (
		<>
			<Nav />
			<div className="forgot_cont">
				{msg.length == 0 ? (
					<div className="pstyles resetst">
						<h2>{t("reset1")}</h2>
						<div className="col-md-8">
							<div>
								<form onSubmit={onsub}>
									<p className="pt">{t("reset2")}</p>
									<div className="clear"></div>
									<p className="p1">
										<i
											style={{ color: colour1, fontSize: "20px" }}
											className="fa fa-check-circle"
											aria-hidden="true"
										></i>{" "}
										{t("reset3")}
									</p>
									<div className="clear"></div>
									<p className="p2">
										<i
											style={{ color: colour2, fontSize: "20px" }}
											className="fa fa-check-circle"
											aria-hidden="true"
										></i>{" "}
										{t("reset4")}
									</p>
									<div className="clear"></div>
									<p className="p3">
										<i
											style={{ color: colour3, fontSize: "20px" }}
											className="fa fa-check-circle"
											aria-hidden="true"
										></i>{" "}
										{t("reset5")}
									</p>
									<div className="clear"></div>
									<p className="p4">
										<i
											style={{ color: colour4, fontSize: "20px" }}
											className="fa fa-check-circle"
											aria-hidden="true"
										></i>
										{t("reset6")}
									</p>
									<div className="clear"></div>
									<p className="p5">
										<i
											style={{ color: colour5, fontSize: "20px" }}
											className="fa fa-check-circle "
											aria-hidden="true"
										></i>{" "}
										{t("password")} == {t("confirmP")}
									</p>
									<div className="clear"></div>
									<div className="form-group">
										<label for="email">{t("reset7")}</label>
										<input
											type="email"
											className="form-control"
											placeholder={t("emailText")}
											value={value3}
											onChange={handleChange3}
										/>
									</div>
									<div className="form-group">
										<label for="password">{t("password")}</label>
										<input
											type="password"
											className="form-control"
											value={value1}
											onChange={handleChange1}
											placeholder={t("password")}
										/>
									</div>
									<div className="form-group">
										<label for="password">{t("confirmP")}</label>
										<input
											type="password"
											className="form-control"
											value={value2}
											onChange={handleChange2}
											placeholder={t("confirmP")}
										/>
									</div>
									{value2 == "" ? (
										""
									) : value1 == value2 ? (
										<p style={{ color: "green", fontWeight: "bold" }}>
											{" "}
											{t("reset8")}{" "}
										</p>
									) : (
										<p style={{ color: "red", fontWeight: "bold" }}>
											{" "}
											{t("reset9")}{" "}
										</p>
									)}
									<div className="phrase_red">{error && "Token Expired"} </div>
									{value3 !== "" &&
									colour1 &&
									colour2 &&
									colour3 &&
									colour4 &&
									colour5 == "green" ? (
										<div className="forgot_btn">
											<button style={{ width: "110px", marginTop: "36px" }}>
												{t("Submit")}
											</button>
										</div>
									) : (
										<div className="forgot_btn">
											<button
												className="pale"
												disabled
												style={{ width: "110px", marginTop: "36px" }}
											>
												{t("Submit")}
											</button>
										</div>
									)}
								</form>
							</div>
						</div>

						<div className="col-md-4"></div>
					</div>
				) : (
					<div>
						<img width="150px" src={sucess} alt="sucess" />
						<h2>{msg}</h2>
						<h4 style={{ marginBottom: "90px" }}>{t("reset10")} </h4>
					</div>
				)}
			</div>
		</>
	);
};

export default Reset;
