import React from "react";
import "./history.css";
import withdraw from "./img/withdraw.png";
import invested from "./img/invested.png";
import Moment from "moment";
const UserTrans = ({ id, name, slug, price, dbdate, description }) => {
	const amount = parseInt(price);
	const d = Date.now();
	const momt = Moment(d).format("MMMM Do, YYYY");
	const momy = Moment().add(-1, "days").format("MMMM Do, YYYY");
	const date = Moment.utc(dbdate.toLocaleString()).format("MMMM Do, YYYY");
	var dat = Moment(dbdate).format(Moment.HTML5_FMT.DATE);
	return (
		<div className="UserTrans">
			<div>{dat}</div>
			<div className="user_inf">
				<img
					src={
						name == "withdraw"
							? withdraw
							: name == "fund"
							? invested
							: name == "invest" && invested
					}
					alt="inv"
				/>
				<span className="TransD">
					{name == "withdraw" ? (
						<>Account Debited</>
					) : name == "invest" ? (
						<>Money Invested</>
					) : (
						name == "fund" && <>Wallet Funded</>
					)}
				</span>
			</div>
			<div className="transact_des mobile_off"><div className="dash_des">{description}</div></div>
			<div className="transact_price">$ {amount.toLocaleString()}</div>
		</div>
	);
};

export default UserTrans;
