import React from "react";
import Nav from "../../../Nav/Nav";
import UserHeader from "../../Header/Header";
import Sidenav from "../../Sidebar/Sidenav";
import "./support.css";
import { useTranslation } from "react-i18next";
import support from "./support.svg";
import useScript from "../../../Widget/useScript";
export default function Support() {
	const { t } = useTranslation();
	useScript("//code.tidio.co/toh77l0tiayyaeaee4supl9xxjvutner.js");
	return (
		<div className="userbg">
			<div className="user_app">
				<UserHeader />
				<Sidenav title={t("help")} />
				<div className="error-container supportcont">
					<img src={support} alt="support" />
					<br />
					<h3 className="error-sub-title">24HRS {t("help")}</h3>
					<h6>{t("users1")}</h6>
					<p>{t("users2")}</p>
					<a className="user_btn sup_btn" href="/app">
						{t("users3")}
					</a>
				</div>
			</div>
		</div>
	);
}
