import React, { useState } from "react";
import Footer from "../../Footer/Footer";
import Nav from "../../Nav/Nav";
import "../css/contact.css";
import contact from "./contact.gif";
function Contact() {
	const [name, setName] = useState(null);
	const [email, setEmail] = useState(null);
	const [message, setMessage] = useState(null);
	const [check, setCheck] = useState(null);
	const handleInputChange = (e) => {
		const { name, value, type } = e.target;
		if (name == "name") {
			setName(value);
		}
		if (name == "email") {
			setEmail(value);
		}
		if (name == "message") {
			setMessage(value);
		}
		if (type == "radio") {
			setCheck(value);
		}
	};
	const onSubmit = (e) => {
		e.preventDefault();
	};

	return (
		<>
			<Nav />
			<section className="contact-section">
				<div className="container">
					<div className="row">
						<div className="col-md-6 contactf">
							<div className="contact-form">
								<h1>Send us a message</h1>
								<p>
									Please fill this short form and we’ll give you special
									attention as quickly as possible
								</p>
								<div className="col-md-6 mobile mobileB">
									<div className="contact--info-area">
										<img src={contact} alt="contactImg" width={"300px"} />
									</div>
								</div>
								<form onSubmit={onSubmit} method="post" className="row">
									<div className="col-md-9">
										Name
										<input
											type="text"
											name="name"
											placeholder="Enter your full name"
											onChange={(e) => handleInputChange(e)}
										/>
									</div>
									<div className="col-md-9">
										Email Address
										<input
											type="email"
											name="email"
											placeholder="Enter your email address"
											onChange={(e) => handleInputChange(e)}
										/>
									</div>
									<div className="col-md-9">
										Message
										<textarea
											name="message"
											placeholder="Message"
											onChange={(e) => handleInputChange(e)}
										></textarea>
									</div>
									<div className="formRadio">
										<p>When can we best reach you?</p>
										<div className="singleBody">
											<label
												className={`singleRadio ${
													check == "Morning" && "Radio_active"
												}`}
												htmlFor="Morning"
											>
												<input
													type="radio"
													value="Morning"
													id="Morning"
													name="time"
													onChange={(e) => handleInputChange(e)}
													checked={check == "Morning"}
												/>
												<span>In the Morning</span>
											</label>
											<label
												className={`singleRadio ${
													check == "Day" && "Radio_active"
												}`}
												htmlFor="Day"
											>
												<input
													type="radio"
													value="Day"
													id="Day"
													name="time"
													onChange={(e) => handleInputChange(e)}
													checked={check == "Day"}
												/>
												<span>During the day</span>
											</label>
											<label
												className={`singleRadio ${
													check == "Evening" && "Radio_active"
												}`}
												htmlFor="Evening"
											>
												<input
													type="radio"
													value="Evening"
													name="time"
													id="Evening"
													onChange={(e) => handleInputChange(e)}
													checked={check == "Evening"}
												/>
												<span>In the evening</span>
											</label>
										</div>
									</div>
									<div className="contact-btn">
										<input
											type="submit"
											name="submit"
											className="col-md-9"
											value="Send Message"
										/>
									</div>
								</form>
							</div>
						</div>
						<div className="col-md-6 windView">
							<div className="contact--info-area">
								<img src={contact} alt="contactImg" width={"300px"} />
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Contact;
