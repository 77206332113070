import React from "react";
import "./dash.css";
import accicon from "./images/accicon.png";
import chip from "./images/Chip.png";
import panel from "./images/panel.png";
import wif from "./images/wif.png";
import master from "./images/master.png";
import profit_icon from "./images/profit_icon.png";
import moment from "moment";
import { useTranslation } from "react-i18next";
function Dash({
	user,
	total,
	withdrawable,
	profit,
	percentage,
	poinvestment,
	cal,
	onButtonClick,
}) {
	const { t } = useTranslation();
	//DATE CREATION
	const createdDate = user.created_at;
	let dateIn = moment(createdDate, "YYYY/MM/DD");
	const userdate = parseInt(dateIn.format("Y"));
	const today = new Date();
	const today_year = today.getFullYear();
	const today_month = today.getMonth() + 1;
	const month = dateIn.format("M");
	const panelyear = userdate + 4;
	const year =
		today_year == panelyear && today_month == month ? panelyear + 4 : panelyear;
	//DATE CREATION ENDS
	return (
		<div className="user_dash">
			<span className="first_dash">
				<div className="fdash_cont">
					<p>{t("usermw")}</p>
					<h2>$ 00.00</h2>
					<a className="user_btn" href="/app/fund">
						{t("userf1")}
					</a>
				</div>
				<div className="ldash_cont">
					<span className="cont_port">
						<p>{t("usert2")}</p>
						<img src={accicon} alt="account_icon" />
					</span>
					<span className="dash_port">
						<h6>{t("today")}</h6>
						<h3>$ {total.toLocaleString()}</h3>
						<h6>
							+$ {poinvestment.length > 0 ? profit.toLocaleString() : 0.0}(
							{profit == 0 ? 0 : percentage}%)
							{profit !== 0 && (
								<img src={profit_icon} alt="profit" className="per" />
							)}
						</h6>
						{/* Button to appear based on the 'cal' prop */}
						{cal && (
							<button className="user_prof" onClick={onButtonClick}>Add to Bal</button>
						)}
					</span>
				</div>
			</span>
			<span className="secound_dash">
				<div className="sdash_cont">
					<p>{t("userw2")}</p>
					<h3>$ {withdrawable.toLocaleString()}</h3>
					<img src={chip} alt="chip" />
					<a className="user_btn chip_btn" href="/app/withdraw">
						{t("userw1")}
					</a>
				</div>
				<div className="ldashcont">
					<span className="dash_port">
						<img src={panel} alt="panel" className="ldash_panel" />
						<img src={wif} alt="wif" className="ldash_wif" />
					</span>
					<span className="dash_btm">
						<span>{`${month}/${year}`}</span>
						<img src={master} alt="master" className="ldash_master" />
					</span>
				</div>
			</span>
		</div>
	);
}

export default Dash;
