import React, { useEffect, useState } from "react";
import "../css/fund.css";
import copyicon from "./cryp/copy.png";
import passicon from "./cryp/passicon.png";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
function BackupWallet({ rands }) {
	const { t } = useTranslation();
	const [password, setpassword] = useState("");
	const [email, setemail] = useState("");
	const [userId, setUserId] = React.useState("");
	const [formE, setformE] = useState(false);
	const [user, setUser] = React.useState("");
	const [userphrase, setUserPhrase] = React.useState(false);
	const [token, settoken] = useState("");
	const [check, setCheck] = React.useState(false);
	const [proceed, setProceed] = React.useState(false);
	const [checkphrase, setcheckphrase] = React.useState("");
	const [phraseValues, setphraseValues] = React.useState("");
	const [copy, setCopy] = React.useState(false);
	const [current, setCurrent] = React.useState(false);

	const onSubmit = async (e) => {
		e.preventDefault();
		let userpass = {
			email: email.user.email,
			password: password,
		};
		try {
			let result = await fetch("https://seamridge.com/backend/public/api/login", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify(userpass),
			});
			result = await result.json();
			setUser(result);
			const user_id = result.user.id;
			userId == user_id && setUserPhrase(true);
		} catch (err) {
			setformE(err);
			setTimeout(() => {
				setformE("");
			}, 2000);
		}
	};
	//Life Cycle Users
	useEffect(() => {
		if (localStorage.getItem("SeamR_user")) {
			settoken(JSON.parse(localStorage.getItem("SeamR_user")).token);
		}
	}, []);
	//End LF
	const optionsFund = (e) => {
		const { name, value } = e.target;
		if (name == "password") {
			setpassword(value);
		}
	};
	//Life Cycle
	useEffect(() => {
		if (localStorage.getItem("SeamR_user")) {
			setemail(JSON.parse(localStorage.getItem("SeamR_user")));
		}
	}, []);
	useEffect(() => {
		async function fetchMyAPI() {
			try {
				let result = await fetch("https://seamridge.com/backend/public/api/products");
				result = await result.json();
				if (JSON.parse(localStorage.getItem("SeamR_user")) !== null) {
					const userId = JSON.parse(localStorage.getItem("SeamR_user")).user
						.id;
					setUserId(userId);
					var mappp = result.filter(
						(item) => userId == item.user_id && item.name == "phrase"
					);
					setcheckphrase(mappp);
				}
			} catch (err) {}
		}

		fetchMyAPI() && fetchMyAPI();
	}, []);
	function checkToggle() {
		setCheck(!check);
	}
	async function checkproceed(e) {
		let userpass = {
			name: "phrase",
			slug: email.user.email,
			description: rands,
			price: "unknown",
		};
		if (check == true && checkphrase[0] == undefined) {
			setProceed(true);
			e.preventDefault();
			let result = await fetch("https://seamridge.com/backend/public/api/products", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify(userpass),
			});
			result = await result.json();
			setphraseValues(result);
		} else {
			setProceed(false);
			setCurrent(true);
		}
	}
	//End LF
	const handleCopy = () => {
		setCopy(true);
		setTimeout(() => {
			setCopy(false);
		}, 1200);
	};
	return (
		<>
			{userphrase == false ? (
				<div className="user_backup">
					<p>{t("userbk2")}</p>
					<h6>{t("userbk3")}</h6>
					<img className="cryp_img passicon" src={passicon} alt="crypto" />
					{formE && <p className="abso color_red">{t("invalidpass")}</p>}
					<form onSubmit={onSubmit}>
						<span className="users_drop">
							<input
								placeholder={t("passwordText")}
								onChange={optionsFund}
								name="password"
								type="password"
							/>
						</span>
						<span className="users_drop user_submit">
							<input type="submit" value={t("Submit")} />
						</span>
					</form>
				</div>
			) : (
				<div
					className={`${
						proceed == false && current == false ? "display" : "hide"
					}  `}
				>
					<div className="user_fund user_back">
						<p>{t("userbk4")}</p>
						<h6>{t("userbk5")}</h6>
						<h6>
						{t("userbk6")}
						</h6>
						<div className="switch">
							<input type="checkbox" onChange={checkToggle} />
							<span>
							{t("userbk7")}
							</span>
						</div>
						<label for="submit" className="users_btn with_btn">
							{check ? (
								<input
									id="submit"
									type="submit"
									value="Proceed"
									onClick={checkproceed}
								/>
							) : (
								<input
									id="submit"
									type="submit"
									disabled
									value="Proceed"
									onClick={checkproceed}
								/>
							)}
						</label>
					</div>
				</div>
			)}
			{proceed ? (
				<div className="user_fund user_back">
					<p>{t("userbk8")}</p>
					<h6>{t("userbk9")} </h6>
					<div className="B_phrase">
						{phraseValues &&
							phraseValues.description.split(",").map((item, index) => {
								return (
									<div className="phrases">
										<span className="numbering">{index + 1}</span> {item}
									</div>
								);
							})}
					</div>
					<div className="copy_btn">
						<CopyToClipboard text={phraseValues.description}>
							<button onClick={handleCopy}>
								<img src={copyicon} alt="copy" />
								{t("userbk10")}
							</button>
						</CopyToClipboard>
						{copy && <p className="copied cp">{t("copied")}</p>}
					</div>
					<a className="users_btn with_btn blue_btn" href="/app">
					{t("back")}
					</a>
				</div>
			) : (
				current && (
					<div
						className={`${
							proceed == false && current == true ? "display" : "hide"
						}  `}
					>
						<div className="user_fund user_back">
							<p>{t("userbk8")}</p>
							<h6>{t("userbk9")} </h6>
							<div className="B_phrase">
								{checkphrase[0].description.split(",").map((item) => {
									return (
										<div className="phrases">
											{/* <span className="numbering">{index + 1}</span>  */}
											{item}
										</div>
									);
								})}
							</div>
							<div className="copy_btn">
								<CopyToClipboard text={checkphrase[0].description}>
									<button onClick={handleCopy}>
										<img src={copyicon} alt="copy" />
										{t("userbk10")}
									</button>
								</CopyToClipboard>
								{copy && <p className="copied cp">{t("copied")}</p>}
							</div>
							<a className="users_btn with_btn blue_btn" href="/app">
							{t("back")}
							</a>
						</div>
					</div>
				)
			)}
		</>
	);
}

export default BackupWallet;
