import React, { useEffect, useState } from "react";
import UserHeader from "../../Header/Header";
import Sidenav from "../../Sidebar/Sidenav";
import "../css/usersPages.css";
import UsersReferrals from "./UsersReferrals";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Referrals() {
	const { t } = useTranslation();
	const history = useNavigate();
	//Effects
	useEffect(() => {
		if (!localStorage.getItem("SeamR_user")) {
			history("/signin");
		}
	}, []);
	const [refer, setrefer] = useState("");
	useEffect(() => {
		if (localStorage.getItem("SeamR_user")) {
			setrefer(JSON.parse(localStorage.getItem("SeamR_user")).user.refer);
		}
	}, []);
	return (
		<div className="userbg">
			<div className="user_app">
				<UserHeader />
				<Sidenav title={t("userr1")} />
				<div className="users_modal">
					<UsersReferrals refercode={refer} />
				</div>
			</div>
		</div>
	);
}

export default Referrals;
