import React, { useState, useEffect } from "react";
import "../css/testimonies.css";
import "../css/pricingTable.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import profile1 from "./profile1.png";
import profile2 from "./profile2.png";
import profile3 from "./profile3.png";
import profile4 from "./profile4.png";
import profile5 from "./profile5.png";
import profile6 from "./profile6.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function Testimonies() {
	const { t } = useTranslation();
	const [matches, setMatches] = useState(
		window.matchMedia("(min-width: 996px)").matches
	);
	const [matcheslow, setMatcheslow] = useState(
		window.matchMedia("(min-width: 616px)").matches
	);

	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
		window
			.matchMedia("(min-width: 996px)")
			.addEventListener("change", (e) => setMatches(e.matches));
		window
			.matchMedia("(min-width: 616px)")
			.addEventListener("change", (e) => setMatcheslow(e.matches));
	}, []);
	const settings = {
		centerMode: false,
		draggable: true,
		arrows: true,
		autoplay: false,
		autoplaySpeed: 1500,
		slidesToShow: 3,
		slidesToScroll: 3,
		dots: true,
	};
	!matcheslow
		? (settings.slidesToShow = 1)
		: !matches
		? (settings.slidesToShow = 2)
		: (settings.slidesToShow = 3);

	settings.slidesToShow == 1
		? (settings.slidesToScroll = 1)
		: settings.slidesToShow == 2
		? (settings.slidesToScroll = 2)
		: (settings.slidesToScroll = 3);
	return (
		<div className="container testimonies">
			<h1 data-aos="fade-down">{t("Ttitle")}</h1>
			<p data-aos="fade-right">{t("TSubTitle")}</p>
			<div className="testimoniesbody" data-aos="fade-up">
				<Slider {...settings}>
					<div>
						<div className="testimony">
							<p>“</p>
							<p>{t("T1")}</p>
							<div className="testimonyProfile">
								<img src={profile1} alt="profile" width={"48px"} />
								<span>Barbara C. Hopkins</span>
							</div>
						</div>
					</div>
					<div>
						<div className="testimony">
							<p>“</p>
							<p>{t("T2")}</p>
							<div className="testimonyProfile">
								<img src={profile2} alt="profile" width={"48px"} />
								<span>Louis J. Francis</span>
							</div>
						</div>
					</div>
					<div>
						<div className="testimony">
							<p>“</p>
							<p>{t("T3")}</p>
							<div className="testimonyProfile">
								<img src={profile3} alt="profile" width={"48px"} />
								<span>Daniel C. Oliver</span>
							</div>
						</div>
					</div>
					<div>
						<div className="testimony">
							<p>“</p>
							<p>{t("T4")}</p>
							<div className="testimonyProfile">
								<img src={profile4} alt="profile" width={"48px"} />
								<span>Gary H. Rogers</span>
							</div>
						</div>
					</div>
					<div>
						<div className="testimony">
							<p>“</p>
							<p>{t("T5")}</p>
							<div className="testimonyProfile">
								<img src={profile5} alt="profile" width={"48px"} />
								<span>Edward R. Shelton</span>
							</div>
						</div>
					</div>
					<div>
						<div className="testimony">
							<p>“</p>
							<p>{t("T6")}</p>
							<div className="testimonyProfile">
								<img src={profile6} alt="profile" width={"48px"} />
								<span>Mary L. Holloway</span>
							</div>
						</div>
					</div>
				</Slider>
			</div>
		</div>
	);
}
export default Testimonies;
