import React, { useEffect, useState } from "react";
import axios from "axios";
function ItemsR({ id, user, plan, amount, token, type }) {
	const onReject = () => {
		let axiosConfig = {
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		};
		axios
			.delete(
				`https://seamridge.com/backend/public/api/products/${id}`,
				axiosConfig
			)
			.then((res) => {
				if (res) {
					window.location.reload();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	return (
		<div className="itemsR" key={id}>
			<span>{user}</span> Request to Transfer <span>${amount}</span> to{" "}
			<span>{plan}</span>
			<span>
				<h2>Transfer Request</h2>
			</span>
			<span>
				<button className="users_btn Rbtn red" onClick={onReject}>
					Delete Request
				</button>
			</span>
		</div>
	);
}

export default ItemsR;
