import React, { useEffect } from "react";
import "../css/miniFoot.css";
import play from "./play.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function MiniFoot() {
	const { t } = useTranslation();
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	return (
		<div className="container">
			<div className="miniFoot">
				<h1 data-aos="fade-right">{t("MFNtitle")}</h1>
				<p data-aos="fade-left"><span>{t("MFNSubTitle")}</span></p>
				<a className="main-btn mnf-btn ml-30" href="/signup" data-aos="fade-up">
					{t("MFBtn1")}
				</a>
			</div>
		</div>
	);
}
export default MiniFoot;
