import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Nav from "../Nav/Nav";
import "./css/hero.css";
import Features from "./Features/Features";
import Growth from "./Growth/Growth";
import Hero from "./Hero/Hero";
import MiniFoot from "./MiniFoot/MiniFoot";
import PricingTable from "./PricingTable/PricingTable";
import Testimonies from "./Testimonies/Testimonies";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import spinner from "./spinner.gif";
import "./css/spinner.css";
import useScript from "../Widget/useScript";
import CounterUpOne from "./counterup/CounterUpOne";
function Home() {
	const [loader, setloader] = useState(true);
	useScript("//code.tidio.co/toh77l0tiayyaeaee4supl9xxjvutner.js");
	useEffect(() => {
		const timer = setTimeout(() => {
			setloader(false);
		}, 4300);
		return () => clearTimeout(timer);
	}, []);
	return (
		<>
			{loader ? (
				<div className="spinner">
					<img src={spinner} alt="spin" />
					<h2>Seamridge.com</h2>
				</div>
			) : (
				<div>
					<Nav />
					<Hero />
					<Growth />
					<PricingTable />
					<Features />
					<CounterUpOne />
					<br />
					<br />
					<br />
					<br />
					<Testimonies />
					<MiniFoot />
					<Footer />
				</div>
			)}
		</>
	);
}

export default Home;
