import React from "react";

class Fwidget extends React.Component {
	componentDidMount() {
		const script = document.createElement("script");
		script.src = "https://www.livecoinwatch.com/static/lcw-widget.js";
		script.async = true;
		document.body.appendChild(script);
	}
	render() {
		return <div></div>;
	}
}
export default Fwidget;
