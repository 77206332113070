import React, { useEffect } from "react";
import Header from "./Header";
import useToggle from "./lib/useToggle";
import Drawer from "./Mobile/Drawer";
import "./css/Nav.css";
import "./css/Style.css";
import "./css/bootstrap.min.css";
import "./css/font-awesome.min.css";
const Nav = ({ lang, optionsState, t }) => {
	const [drawer, drawerAction] = useToggle(false);
	return (
		<div>
			<Drawer
				drawer={drawer}
				action={drawerAction.toggle}
				lang={lang}
				optionsState={optionsState}
				t={t}
			/>
			<Header action={drawerAction.toggle} />
		</div>
	);
};

export default Nav;
