import React, { useEffect, useState } from "react";
import "../css/fund.css";
import giftbox from "./cryp/giftbox.png";
import refcode from "./cryp/refcode.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
function UsersReferrals({ refercode }) {
	const [copy, setCopy] = React.useState(false);
	const { t } = useTranslation();
	const handleCopy = () => {
		setCopy(true);
		setTimeout(() => {
			setCopy(false);
		}, 99200);
	};

	return (
		<>
			<div className="user_fund user_back accounM">
				<p>{t("userr2")}</p>
				<h6>
				{t("userr3")}{" "}
					<img className="giftbox" src={giftbox} alt="accmanager" />
				</h6>
				<p>
				{t("userr4")}
				</p>
				<div className="referbox">
					<span className="refer_left">
						<h5> $ 00.00</h5>
						<p>{t("userr5")}</p>
					</span>
					<span className="refer_mid"></span>
					<span className="refer_right">
						<h3>0</h3>
						<p>{t("userr1")}</p>
					</span>
				</div>
				<div className="refercode">
					<img src={refcode} /> <span>{t("userr6")}: #{refercode}</span>
				</div>
				<div className="copy_btn">
					<CopyToClipboard text={"accountmanager@seamridge.com"}>
						<button
							className="users_btn with_btn blue_btn"
							onClick={handleCopy}
						>
							{t("userr7")}
						</button>
					</CopyToClipboard>
					{copy && <p className="copied cpr cp">{t("copied")}</p>}
				</div>
			</div>
		</>
	);
}

export default UsersReferrals;
