import React, { useEffect } from "react";
import "../css/growth.css";
import growthOne from "./growth1.png";
import growthTwo from "./growth2.png";
import growthThree from "./growth3.png";
import growthFour from "./growth4.png";
import growthFive from "./growth5.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function Growth() {
	const { t } = useTranslation();
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	return (
		<div className="growthF">
			<div className="container GF">
				<div className="growthHead" data-aos="fade-down">
					<h1>{t("Gtitle")}</h1>
					<p>{t("GsubTitle")}</p>
				</div>
				<div className="growthBodyTop" data-aos="fade-right">
					<span className="growthContent">
						<img src={growthTwo} alt="wallet" width="90px" />
						<h3>{t("GCTitle")}</h3>
						<p>{t("GCsubTitle")}</p>
					</span>
					<span className="growthContent">
						<img src={growthFour} alt="wallet" width="90px" />
						<h3>{t("GGTitle")}</h3>
						<p className="ggold">{t("GGsubTitle")}</p>
					</span>
					<span className="growthContent">
						<img src={growthFive} width="90px" alt="wallet" />
						<h3>{t("GRTitle")}</h3>
						<p>{t("GRsubTitle")}</p>
					</span>
				</div>
				<div className="growthBodyBottom" data-aos="fade-left">
					<span className="growthContent">
						<img src={growthOne} alt="wallet" width="90px" />
						<h3>{t("GATitle")}</h3>
						<p>{t("GAsubTitle")}</p>
					</span>
					<span className="growthContent">
						<img src={growthThree} alt="wallet" width="90px" />
						<h3>{t("GOTitle")}</h3>
						<p>{t("GOsubTitle")}</p>
					</span>
				</div>
			</div>
		</div>
	);
}

export default Growth;
