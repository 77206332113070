import React, { useEffect } from "react";
import trustOne from "./trust1.png";
import trustTwo from "./trust2.png";
import trustThree from "./trust3.png";
import trustSix from "./trust6.png";
import trustSeven from "./trust7.png";
import trustEight from "./trust8.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "../css/about.css";
import { useTranslation } from "react-i18next";
function AboutHero() {
	const { t, i18n } = useTranslation();
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	return (
		<>
			<section class="appie-hero-area aboutCont">
				<div className="container">
					<div className="container_bg contbk">
						<div className="about_hero" data-aos="fade-right">
							<div className="about-hero-content">
								<h1 className="about-title">{t("Atitle")}</h1>
								<p className="about-body">{t("ASubtitle")}</p>
								<div className="lowerDk">
									<p>{t("Htrusted")}</p>
									<br />
									<div className="aboutImgList">
										<img src={trustThree} alt="Binance" width={"100px"} />
										<img src={trustEight} alt="crypto" width={"100px"} />
										<img src={trustOne} alt="coinbase" width={"100px"} />
										<img src={trustSix} alt="sequoia" width={"100px"} />
										<img src={trustSeven} alt="blockchain" width={"150px"} />
										<img src={trustTwo} alt="opensea" width={"100px"} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default AboutHero;
