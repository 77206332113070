import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Login from "../Auth/login/login";
import Dash from "./Dash/Dash";
import moment from "moment";
import UserHeader from "./Header/Header";
import Sidenav from "./Sidebar/Sidenav";
import "./userapp.css";
import History from "./UserHistory/History";
import Market from "./UserMarket/Market";
import { useTranslation } from "react-i18next";
import axios from "axios";
function Index() {
	//States
	const { t } = useTranslation();
	const [user, setuser] = useState(null);
	const [User, setUser] = useState([]);
	const [userId, setUserId] = useState("");
	const [doinv, setDoinv] = useState("");
	const [Plan, setPlan] = useState("");
	const [firstid, setfirstid] = useState("");
	const [trig, setTrig] = React.useState(false);
	const [usersname, setusersname] = useState("");
	const [token, settoken] = useState("");
	const [accumulatedProfit, setAccumulatedProfit] = useState(0);
	const [witminus, setWitminus] = React.useState("");
	const [pushedToDatabase, setpushedToDatabase] = useState(false);
	const [fund, setFund] = useState([
		{
			id: 0,
			name: "BASIC",
			amount: 0,
		},
		{
			id: 1,
			name: "PREMIUM",
			amount: 0,
		},
		{
			id: 2,
			name: "PRO",
			amount: 0,
		},
	]);
	useEffect(() => {
		if (localStorage.getItem("SeamR_user")) {
			settoken(JSON.parse(localStorage.getItem("SeamR_user")).token);
			setusersname(JSON.parse(localStorage.getItem("SeamR_user")).user.name);
		}
		if (localStorage.getItem("SeamR_pushedToDatabase")) {
			setpushedToDatabase(
				JSON.parse(localStorage.getItem("SeamR_pushedToDatabase"))
			);
		}
	}, []);
	//End States
	const history = useNavigate();
	//Effects
	useEffect(() => {
		if (!localStorage.getItem("SeamR_user")) {
			history("/signin");
		}
	}, []);
	useEffect(() => {
		setuser(JSON.parse(localStorage.getItem("SeamR_user")));
	}, []);
	let total = 0;
	User.map((item) => {
		const user_id = item.user_id;
		const wit_id = user_id == userId && item.name == "fund" && item.accept == 1;
		wit_id && (total = total + parseInt(item.price));
	});
	useEffect(() => {
		async function fetchMyAPI() {
			try {
				let result = await fetch(
					"https://seamridge.com/backend/public/api/products"
				);
				result = await result.json();
				if (JSON.parse(localStorage.getItem("SeamR_user")) !== null) {
					const userId = JSON.parse(localStorage.getItem("SeamR_user")).user.id;
					setUserId(userId);
					setUser(result);
					const doinvestment = result.filter(
						(item) =>
							item.user_id == userId &&
							item.name == "fund" &&
							item.created_at &&
							item.accept == 1 &&
							item.description != "Profit Added"
					);
					// Convert the created_at string to a Date object
					const length = doinvestment.length - 1;
					const createdAtDate = new Date(doinvestment[0].created_at);
					const currentDate = new Date(doinvestment[length].created_at);
					// Calculate the time difference in milliseconds
					let timeDifference = new Date() - createdAtDate;
					// Calculate the number of days
					const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
					if (doinvestment.length == 1) {
						setDoinv(doinvestment[0].created_at);
					}
					if (doinvestment.length > 1 && daysDifference > 2) {
						if (
							currentDate >
							new Date(createdAtDate.getTime() + 2 * 24 * 60 * 60 * 1000)
						) {
							timeDifference = new Date() - currentDate;
						}
					}
					if (doinvestment.length > 1) {
						setDoinv(doinvestment[length].created_at);
					}
					console.log(doinv, "kkk");
					setPlan(doinvestment[0].slug);
					setfirstid(doinvestment[0].id);
					localStorage.setItem(
						"SeamR_plan",
						JSON.stringify(doinvestment[0].slug)
					);
					const witminu = result.filter(
						(item) => item.user_id == userId && item.name == "withdraw"
					);
					setWitminus(witminu);
				}
			} catch (err) {}
		}

		fetchMyAPI();
	}, []);
	//End Effects

	//algo
	const uwitminus = User.filter(
		(item) =>
			item.user_id == userId && item.name == "withdraw" && item.accept == 1
	);
	let withprice = 0;
	uwitminus.map((item) => {
		withprice = withprice + parseInt(item.price);
	});

	// percentage algo
	let startDate = new Date(doinv);
	let myDate = new Date();
	let diff = Math.floor((myDate - startDate) / (1000 * 60 * 60 * 24));
	//replace
	const fixedDiff = 2;
	let totper = 0;
	let percentage = 0;
	let sd = moment(startDate).format("MMMM Do, YYYY");
	let md = moment(myDate).format("MMMM Do, YYYY");

	const poinvestment = User.filter(
		(item) =>
			item.user_id == userId &&
			item.name == "fund" &&
			item.slug != "None" &&
			item.id &&
			item.accept == 1
	);
	let per;
	const latestItem = poinvestment.pop();
	if (latestItem) {
		let price = latestItem.price;
		let plan = latestItem.slug;
		let basic = (price / 100) * 14;
		let premium = (price / 100) * 21;
		let pro = (price / 100) * 28;
		let weekly = (price / 100) * 0.3;
		let daily = (price / 100) * 0.5;
		let priceper =
			plan == "BASIC"
				? basic
				: plan == "PREMIUM"
				? premium
				: plan == "PRO"
				? pro
				: plan == "WEEKLY"
				? weekly
				: plan == "MONTHLY" && daily;

		if (sd == md) {
		} else {
			let answer = priceper + fixedDiff * priceper - priceper;
			if (doinv === null || isNaN(totper)) {
				totper = 0;
			} else {
				totper = totper + answer;
			}
		}
	}
	if (sd == md) {
	} else {
		let basic = 14 + diff * 14 - 14;
		let premium = 21 + diff * 21 - 21;
		let pro = 28 + diff * 28 - 28;
		let weekly = 0.3 + diff * 0.3 - 0.3;
		let daily = 0.5 + diff * 0.5 - 0.5;
		per =
			Plan == "BASIC"
				? basic
				: Plan == "PREMIUM"
				? premium
				: Plan == "PRO"
				? pro
				: Plan == "WEEKLY"
				? weekly
				: Plan == "DAILY" && daily;
		percentage = per == false ? 0 : percentage + parseFloat(per);
	}
	//days cal

	// Convert the created_at string to a Date object
	const createdAtDate = new Date(startDate);

	// Calculate the time difference in milliseconds
	const timeDifference = new Date() - createdAtDate;

	// Calculate the number of days
	const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
	// Calculate the maximum percentage after the given number of days

	let tots;
	let withdrawable;

	if (daysDifference < 2) {
		tots = total - withprice;
		withdrawable = tots;
	} else {
		tots = total - withprice;
		withdrawable = tots;
	}

	const handleButtonClick = async () => {
		// Check if it's the 2nd day and data hasn't been pushed
		// Accumulate profit for a maximum of 2 days
		if (latestItem) {
			let price = latestItem.price;
			let plan = latestItem.slug;
			let basic = (price / 100) * 14;
			let premium = (price / 100) * 21;
			let pro = (price / 100) * 28;
			let weekly = (price / 100) * 0.3;
			let daily = (price / 100) * 0.5;
			let priceper =
				plan == "BASIC"
					? basic
					: plan == "PREMIUM"
					? premium
					: plan == "PRO"
					? pro
					: plan == "WEEKLY"
					? weekly
					: plan == "MONTHLY" && daily;

			if (sd == md) {
			} else {
				let answer = priceper + fixedDiff * priceper - priceper;
				if (doinv === null || isNaN(totper)) {
					totper = 0;
				} else {
					totper = totper + answer;
					console.log(totper, "nn");
				}
			}
		}

		if (true) {
			try {
				let result = {
					name: "fund",
					slug: "None",
					description: "Profit Added",
					price: totper,
					username: `${usersname} profit added:${totper} `,
					usertoken: token,
					accept: 1,
				};
				let axiosConfig = {
					headers: {
						"Content-Type": "application/json;charset=UTF-8",
						Accept: "application/json",
						Authorization: "Bearer " + token,
					},
				};
				axios
					.post(
						"https://seamridge.com/backend/public/api/products",
						result,
						axiosConfig
					)
					.then((res) => {
						localStorage.setItem(
							"SeamR_pushedToDatabase",
							JSON.stringify(true)
						);
						window.location.reload();
					})
					.catch((err) => {});
				// setpushedToDatabase(true);
			} catch (error) {
				// Handle error if needed
				console.error("Error pushing to database:", error);
			}
		}
	};
	if (daysDifference >= 2) {
		totper = 0;
		percentage = 0;
	}
	//end algo
	localStorage.setItem(
		"SeamR_users",
		JSON.stringify({
			total: tots,
			profit: totper,
			resetid: firstid,
			withdrawable: withdrawable,
		})
	);
	//handling
	const handleOne = () => {
		setTrig(false);
	};
	const handleTwo = () => {
		setTrig(true);
	};

	return (
		<div className="userbg">
			<div className="user_app">
				<UserHeader />
				<Sidenav setTrig={setTrig} title={t("Dashboard")} />
				<Dash
					user={user ? user.user : "user"}
					total={tots}
					withdrawable={withdrawable}
					profit={totper}
					percentage={percentage}
					poinvestment={poinvestment}
					cal={daysDifference >= 2 && !pushedToDatabase}
					onButtonClick={handleButtonClick}
				/>
			</div>
			<div className="user_cont_end">
				<button
					onClick={handleOne}
					className={trig ? "light" : "user_cont_active"}
				>
					{t("history")}
				</button>
				<span className="space_r"></span>
				<button
					onClick={handleTwo}
					className={trig ? "user_cont_active" : "light"}
				>
					{t("market")}
				</button>
				{!trig ? <History /> : <Market />}
			</div>
		</div>
	);
}
export default Index;
