import React, { useEffect, useState } from "react";
import "../css/fund.css";
import copyicon from "./cryp/copy.png";
import accmanager from "./cryp/accmanager.png";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
function AccountManager() {
	const { t } = useTranslation();
	const [copy, setCopy] = React.useState(false);

	const handleCopy = () => {
		setCopy(true);
		setTimeout(() => {
			setCopy(false);
		}, 1200);
	};
	return (
		<>
			<div className="user_fund user_back accounM">
				<p>{t("usera2")}</p>
				<h6>{t("usera3")} </h6>
				<div className="account_m">
					<img src={accmanager} alt="accmanager" />
				</div>
				<div className="copy_btn">
					<CopyToClipboard text={"accountmanager@seamridge.com"}>
						<button onClick={handleCopy}>
							<img src={copyicon} alt="copy" />
							accountmanager@seamridge.com
						</button>
					</CopyToClipboard>
					{copy && <p className="copied cp">{t("copied")}</p>}
				</div>
				<a
					className="users_btn with_btn blue_btn"
					href="mailto: accountmanager@seamridge.com"
				>
					{t("send")}
				</a>
			</div>
		</>
	);
}

export default AccountManager;
