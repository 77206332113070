import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import UserMarket from "./UserMarket";
function Market() {
	const [user, setUser] = React.useState([]);
	const [pageNumber, setPageNumber] = useState(0);
	const usersPerPage = 10;
	const pagesVisited = pageNumber * usersPerPage;

	useEffect(() => {
		async function fetchMyAPI() {
			try {
				let result = await fetch(
					"https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false"
				);
				result = await result.json();
				setUser(result);
			} catch (err) {}
		}

		fetchMyAPI() && fetchMyAPI();
	}, []);
	// Get current posts
	const currentPosts = user.slice(pagesVisited, pagesVisited + usersPerPage);
	// Change page
	const pageCount = Math.ceil(user.length / usersPerPage);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};
	return (
		<div id="market" className="history">
			{currentPosts.length == 0 ? (
				<center>
					<br />
					<h6>Market Loading....</h6>
				</center>
			) : (
				<div>
					<div className="transct">
						<div className="coin_name">Coin Name</div>
						<div className="transact_details market_D">Current Price</div>
						<div className="transact_header mobile_off">24h</div>
						<div className="transact_priceH newtakeoff">Market Cap</div>
					</div>
					<div className="H_line"></div>
					{currentPosts.map((item) => {
						return (
							<UserMarket
								key={item.market_cap_rank}
								id={item.market_cap_rank}
								image={item.image}
								name={item.name}
								market_cap={item.market_cap.toLocaleString()}
								change={item.price_change_percentage_24h.toFixed(2)}
								price={item.current_price.toLocaleString()}
							/>
						);
					})}
					<ReactPaginate
						previousLabel={"<"}
						nextLabel={">"}
						pageCount={pageCount}
						onPageChange={changePage}
						containerClassName={"paginationBttns"}
						previousLinkClassName={"previousBttn"}
						nextLinkClassName={"nextBttn"}
						disabledClassName={"paginationDisabled"}
						activeClassName={"paginationActive"}
					/>
				</div>
			)}
		</div>
	);
}

export default Market;
