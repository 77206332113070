import React from "react";
// import "./css/hero.css";
import dash from "./images/dash.png";
import fundw from "./images/fundw.png";
import withdraw from "./images/withdraw.png";
import market from "./images/market.png";
import history from "./images/history.png";
import transferf from "./images/transferf.png";
import pension from "./images/pension.png";
import backupw from "./images/backupw.png";
import referrals from "./images/referrals.png";
import accountm from "./images/accountm.png";
import help from "./images/help.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Sidebar({ openClass, setTrig, on, setOn }) {
	const { t } = useTranslation();
	//handling
	const handleOne = () => {
		setTrig(false);
		if (on == true) {
			setOn(!on);
		}
	};
	const handleTwo = () => {
		setTrig(true);
		if (on == true) {
			setOn(!on);
		}
	};
	return (
		<nav className={`wnav ${openClass == "open" ? "opneSidebar" : "wnav"}`}>
			<div className="txt">{t("wallet")}</div>
			<div className="border"></div>
			<ul className="navlist">
				<li>
					<Link className="menu-item" exact="true" to="/app">
						<img src={dash} alt="dash" />
						{t("Dashboard")}
					</Link>
				</li>
				<li>
					<Link className="menu-item" exact="true" to="/app/fund">
						<img src={fundw} alt="fundw" />
						{t("userf1")}
					</Link>
				</li>
				<li>
					<Link className="menu-item" exact="true" to="/app/withdraw">
						<img src={withdraw} alt="withdraw" />
						{t("userw1")}
					</Link>
				</li>
				<li>
					<a className="menu-item" href="/app#market" onClick={handleTwo}>
						<img src={market} alt="market" />
						{t("market")}
					</a>
				</li>
				<li>
					<a className="menu-item" href="/app#history" onClick={handleOne}>
						<img src={history} alt="fundw" />
						{t("history")}
					</a>
				</li>
				<li>
					<Link className="menu-item" exact="true" to="/app/transfer">
						<img src={transferf} alt="transferf" />
						{t("usert1")}
					</Link>
				</li>
				<li>
					<Link className="menu-item" exact="true" to="/app/pension">
						<img src={pension} alt="pension" />
						{t("userp1")}
					</Link>
				</li>
				<li>
					<Link className="menu-item" exact="true" to="/app/backup-wallet">
						<img src={backupw} alt="backupw" />
						{t("userbk1")}
					</Link>
				</li>
				<li>
					<Link className="menu-item" exact="true" to="/app/referrals">
						<img src={referrals} alt="referrals" />
						{t("userr1")}
					</Link>
				</li>
			</ul>
			<br />
			<div className="border"></div>
			<ul className="navlist">
				<li>
					<Link className="menu-item" exact="true" to="/app/account-manager">
						<img src={accountm} alt="dash" />
						{t("usera1")}
					</Link>
				</li>
				<li>
				<Link className="menu-item" exact="true" to="/app/support">
						<img src={help} alt="dash" />
						{t("help")}
					</Link>
				</li>
			</ul>
		</nav>
	);
}
export default Sidebar;
