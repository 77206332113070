import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import logo from "./logo.png";
import { useTranslation } from "react-i18next";
import useScript from "../Widget/useScript";
function Footer({ className }) {
	useScript(
		"https://widgets.coingecko.com/coingecko-coin-price-marquee-widget.js"
	);
	const { t } = useTranslation();
	const currentTime = new Date();
	const year = currentTime.getFullYear();
	return (
		<>
			<coingecko-coin-price-marquee-widget
				coin-ids="bitcoin,eos,ethereum,litecoin,ripple,tether,usd,binancecoin,dogecoin,matic-network,aave-polygon-aave,solana,polkadot,litecoin,shiba-inu,tron,uniswap,dai,chainlink,monero,bitcoin-cash"
				currency="usd"
				background-color="#ffffff"
				locale="en"
			></coingecko-coin-price-marquee-widget>
			<section className={`appie-footer-area ${className || ""}`}>
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6">
							<div className="footer-about-widget">
								<div className="appie-logo-box">
									<Link to="/">
										<img src={logo} alt="logo" width={"40px"} />
										<span>seamridge</span>
									</Link>
								</div>
								<p>{t("footSubTitle")}</p>
								<div className="offcanvas-social footerSocial">
									<ul>
										<li>
											<a href="https://github.com/seamridgeCO">
												<i className="fab fa-github"></i>
											</a>
										</li>
										<li>
											<a href="https://github.com/seamridgeCO">
												<i className="fab fa-linkedin"></i>
											</a>
										</li>
										<li>
											<a href="https://github.com/seamridgeCO">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										{/* <li>
											<a href="https://github.com/seamridgeCO">
												<i className="fab fa-telegram"></i>
											</a>
										</li> */}
									</ul>
								</div>
								<a href="mailto: hello@seamridge.com">
									Hello@seamridge.com
								</a>
							</div>
							<div className="footerAddress">
								Switzerland
								<p>Sondanella 104 - 7545 Guarda, Switzerland</p>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="footer-navigation">
								<h4 className="title">{t("footH1")}</h4>
								<ul>
									<li>
										<Link to="/market">{t("footL1")}</Link>
									</li>
									<li>
										<Link to="/about">{t("footL2")}</Link>
									</li>
									<li>
										<a
											href="https://github.com/seamridgeCO/docs/blob/main/security/security.md"
											alt="faq"
										>
											{t("footL3")}
										</a>
									</li>
									<li>
										<a
											href="https://github.com/seamridgeCO/docs/blob/main/FAQs/FAQs.md"
											alt="faq"
										>
											{t("footL4")}
										</a>
									</li>
									<li>
										<a
											href="https://github.com/seamridgeCO/docs/blob/main/terms/terms.md"
											alt="terms"
										>
											{t("footL5")}
										</a>
									</li>
									<li>
										<a
											href="https://github.com/seamridgeCO/docs/blob/main/privacy/privacy.md"
											alt="privacy"
										>
											{t("footL6")}
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="footer-navigation footerProducts">
								<h4 className="title">{t("footH2")}</h4>
								<ul>
									<li>
										<Link to="/signin">{t("footL7")}</Link>
									</li>
									<li>
										<a
											href="https://github.com/seamridgeCO/docs/blob/main/security/security.md"
											alt="faq"
										>
											{t("footL8")}
										</a>
									</li>
									<li>
										<Link to="/signin">{t("footL9")}</Link>
									</li>
									<li>
										<Link to="/signup">{t("footL10")}</Link>
									</li>
									<li>
										<Link to="/signin">{t("footL11")}</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="footer-navigation">
								<h4 className="title">{t("footH3")}</h4>
								<ul>
									<li>
										<a href="#plans">{t("footL12")}</a>
									</li>
									<li>
										<Link to="/signin">{t("footL13")}</Link>
									</li>
									<li>
										<Link to="/signin">{t("footL14")}</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div>
						<div>
							<div className="footer-copyright">
								<div className="copyright-text">
									<p>
										© Seamridge {year}. {t("footEnd")}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Footer;
