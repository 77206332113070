import React from "react";
import "./market.css";
const UserMarket = ({ id, name, market_cap, change, price, image }) => {
	return (
		<div className="UserTrans MarketTrans">
			<div className="user_inf">
				<img src={image} alt="inv" />
				<span className="TransD">{name}</span>
			</div>
			<div className="current_price">$ {price}</div>
			<div className="mobile_off market_des">
				<div className="dash_des">{change}</div>
			</div>
			<div className="newtakeoff"> {market_cap}</div>
		</div>
	);
};

export default UserMarket;
