import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Adminnav from "./Sidebar/Sidenav";
function AdminDash() {
	const history = useNavigate();
	useEffect(() => {
		if (!localStorage.getItem("SeamRAdmin_Demz")) {
			history("/admin");
		}
	}, []);
	return (
		<>
			<div className="container">
				<Adminnav />
				<div className="adminbody">
					<h2>Welcome to Admin Panel</h2>
					<br />
					<p>please use the nav bar to navigate</p>
					<br />
					<p>
						To accept users withdrawal Request please select the Withdraw
						Request from your Nav Bar
					</p>
					<br />
					<p>
						To accept users Transfer Request please select the Transfer Request
						from your Nav Bar
					</p>
					<br />
					<p>
						To accept users Pention and Invest Request please select the Funds
						Pension & Invest Requests from your Nav Bar.
						<h2>
							please NOTE That you must accept both Funds Request together with
							either the Pension or Invest Request as they are together, just
							match the amount and plans and then accept both
						</h2>
					</p>
					<br />
					<p>
						Please if u want to add or substract from users balance or see users
						history please select user from Nav Bar and click the select user
						button
					</p>
				</div>
			</div>
		</>
	);
}

export default AdminDash;
