import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./lng/en.json";
import de from "./lng/de.json";
import es from "./lng/es.json";
import it from "./lng/it.json";
i18next.use(initReactI18next).init({
	resources: {
		en: {
			translation: en,
		},
		de: {
			translation: de,
		},
		es: {
			translation: es,
		},
		it: {
			translation: it,
		},
	},
	lng: localStorage.getItem("lang") || "en",
});

export default i18next;
