import React, { useState, useEffect } from "react";
import logo from "./logo.png";
import profile from "./user.png";
import { Link } from "react-router-dom";
import "./header.css";
import Dropdown from "./dropdown";
import { useTranslation } from "react-i18next";
function UserHeader() {
	const [users, setuser] = useState(null);
	const [userplan, setuserplan] = useState(null);
	const [lang, setlang] = useState(localStorage.getItem("lang"));
	const { t, i18n } = useTranslation();
	useEffect(() => {
		setuser(JSON.parse(localStorage.getItem("SeamR_user")));
		if (localStorage.getItem("SeamR_plan")) {
			setuserplan(JSON.parse(localStorage.getItem("SeamR_plan")));
		}
	}, []);
	const optionsState = (e) => {
		const { name, value } = e.target;
		if (name == "language") {
			setlang(value);
			i18n.changeLanguage(value);
			localStorage.setItem("lang", value);
		}
	};
	const user = users ? users.user : "user";
	return (
		<>
			<div className="user_head"> 
				<Link className="logo_class" exact="true" to="/app">
					<img src={logo} alt="logo" className="user_logo" />
					<span>seamridge</span>
				</Link>
				<span className="user_lang">
					<select name="language" value={lang} onChange={optionsState}>
						<option value={"en"}>EN</option>
						<option value={"de"}>DE</option>
						<option value={"it"}>IT</option>
						<option value={"es"}>ES</option>
					</select>
				</span>
				<span className="user_right">
				<span className="user_lang_mobile">
					<select name="language" value={lang} onChange={optionsState}>
						<option value={"en"}>EN</option>
						<option value={"de"}>DE</option>
						<option value={"it"}>IT</option>
						<option value={"es"}>ES</option>
					</select>
				</span>
					<span className="user_profile">
						<img src={profile} alt="user" />
					</span>
					<span className="user_name">{user.name}</span>
					<span className="dropdown">
						{/* <select name="userdown" value={logout} onChange={optionsState}>
							<option value={"true"}>Logout</option>
							<option value={"yan"}>Logout</option>
						</select> */}
						<Dropdown />
					</span>
				</span>
			</div>
		</>
	);
}
export default UserHeader;
