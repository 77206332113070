import React, { useEffect } from "react";
import "../css/pricingTable.css";
import SimpleSlider from "./MobileTable";
import Premium from "./premium.png";
import pro from "./pro.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function PricingTable() {
	const { t } = useTranslation();
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	return (
		<div className="pTable">
			<div className="container">
				<div className="pricingtable priceWind" id="plans">
					<div className="pricecont" data-aos="fade-right">
						<h1>{t("Ititle")}</h1>
						<p>{t("IsubTitle")}</p>
					</div>
					<div className="tabed-content" data-aos="fade-right">
						<div className="row justify-content-center windowt">
							<div className="col-lg-4 col-md-6 wow animated fadeInLeft">
								<div className="pricing-one__single">
									<div className="pricig-heading">
										<h6>{t("IBTitle")}</h6>
										<div className="price-range">
											<span>$100 - $9,999</span>
										</div>
										<div className="pricing-btn mt-35">
											<a className="table-btn" href="/signin">
												{t("IBtn")}
											</a>
											<div className="table-hr"></div>
										</div>
									</div>
									<div className="pricig-body">
										<ul>
											<li>
												<i className="fal fa-check" /> {t("Ii1")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("IB")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("Ii2")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("Ii3")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("Ii4")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("IBP1")}
											</li>
											<li className="v_opa_none">
												<i className="fal fa-check" /> 24 hours support
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 wow animated fadeInLeft">
								<div className="pricing-one__single">
									<div className="pricig-heading">
										<h6>{t("IPreTitle")}</h6>
										{/* <span className="tPlan">
											<img src={Premium} alt="Premium" />
										</span> */}
										<div className="price-range">
											<span>$10,000 - $99,999</span>
										</div>
										<div className="pricing-btn mt-35">
											<a className="table-btn" href="/signin">
												{t("IBtn")}
											</a>
											<div className="table-hr"></div>
										</div>
									</div>
									<div className="pricig-body">
										<ul>
											<li>
												<i className="fal fa-check" /> {t("Ii1")}
											</li>
											<li>
												<i className="fal fa-check" />
												{t("IPre")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("Ii2")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("Ii3")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("Ii4")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("IBP1")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("IPP1")}
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 wow animated fadeInLeft">
								<div className="pricing-one__single">
									<div className="pricig-heading">
										<h6>{t("IProTitle")}</h6>
										{/* <span className="tPlan">
											<img src={pro} alt="Pro" />
										</span> */}
										<div className="price-range">
											<span>$100,000+</span>
										</div>
										<div className="pricing-btn mt-35">
											<a className="table-btn" href="/signin">
												{t("IBtn")}
											</a>
											<div className="table-hr"></div>
										</div>
									</div>
									<div className="pricig-body">
										<ul>
											<li>
												<i className="fal fa-check" /> {t("Ii1")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("IPro")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("Ii2")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("Ii3")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("Ii4")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("IPro1")}
											</li>
											<li>
												<i className="fal fa-check" /> {t("IPP1")}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<SimpleSlider />
					</div>
				</div>
			</div>
		</div>
	);
}

export default PricingTable;
