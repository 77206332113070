import React, { useEffect, useState } from "react";
import "../css/fund.css";
import btc from "./cryp/btc.png";
import eth from "./cryp/eth.png";
import usdt from "./cryp/usdt.png";
import axios from "axios";
import { useTranslation } from "react-i18next";
function UserPension() {
	const { t } = useTranslation();
	const [pack, setpack] = useState("");
	const [pmethod, setpmethod] = useState("");
	const [amount, setamount] = useState("");
	const [formE, setformE] = useState(false);
	const [formNext, setformNext] = useState(false);
	const [token, settoken] = useState("");
	const [usersname, setusersname] = useState("");
	//Life Cycle Users
	useEffect(() => {
		if (localStorage.getItem("SeamR_user")) {
			settoken(JSON.parse(localStorage.getItem("SeamR_user")).token);
			setusersname(JSON.parse(localStorage.getItem("SeamR_user")).user.name);
		}
	}, []);
	//End LF
	const optionsFund = (e) => {
		const { name, value } = e.target;
		if (name == "package") {
			setpack(value);
		}
		if (name == "method") {
			setpmethod(value);
		}
		if (name == "amount") {
			setamount(value);
		}
	};
	const onSubmit = (e) => {
		e.preventDefault();
		//Rules
		if (
			pack == "" ||
			amount == "" ||
			pmethod == "" ||
			(pack && amount && pmethod == "")
		) {
			setformE(true);
			setTimeout(() => {
				setformE(false);
			}, 2000);
		} else {
			setformE(false);
			setformNext(true);
			let result = {
				name: "fund",
				slug: pack,
				description: "Deposit",
				price: amount,
				username: usersname,
				usertoken: token,
				accept: 0,
			};
			let invest = {
				name: "invest",
				slug: pack,
				description: "Pension Fund",
				price: amount,
				username: usersname,
				usertoken: token,
				accept: 0,
			};
			let axiosConfig = {
				headers: {
					"Content-Type": "application/json;charset=UTF-8",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
			};
			axios
				.all([
					axios.post("https://seamridge.com/backend/public/api/products", result, axiosConfig),
					axios.post("https://seamridge.com/backend/public/api/products", invest, axiosConfig),
				])
				.then(axios.spread((data1, data2) => {}))
				.catch((err) => {});
		}
	};

	return (
		<>
			{formNext ? (
				<div>
					<div className="user_fund">
						<p>{t("userf7")}</p>
						<h6>
						{t("userf8")} {pmethod} {t("userf9")}
						</h6>
						<img
							className="cryp_img"
							src={
								pmethod == "BITCOIN"
									? btc
									: pmethod == "ETHEREUM"
									? eth
									: pmethod == "USDT" && usdt
							}
							alt="crypto"
						/>
					</div>
					<div className="user_fundbtm">
						<p>{t("userf10")}</p>
						<h6>
							{pmethod == "BITCOIN"
								? "bc1q58cvuh3dpd6asr2jfgxpj q2p4nfrjc4zw3letf"
								: pmethod == "ETHEREUM"
								? "0x68f0fc8C4b4058bD32dBB6 e11E5407dBeD566C5A"
								: pmethod == "USDT" &&
								  "TVxSX3izPP3bknB1VPWp1 9J3uvcLdfGw43"}
						</h6>
					</div>
					<div className="user_fundbtm">
						<p>{t("userf11")}</p>
						<h6>{pmethod}</h6>
					</div>
					<span className="users_btn">
						<a href="/app">{t("back")}</a>
					</span>
				</div>
			) : (
				<div className="user_fund">
					<p>{t("userp2")}</p>
					<h4>
					{t("userp3")}
					</h4>
					<br />
					<p>{t("userp4")}</p>
					<br />
					{formE && (
						<p className="abso color_red">{t("complete")}</p>
					)}
					<form onSubmit={onSubmit}>
						<span className="users_drop">
							<select value={pack} onChange={optionsFund} name="package">
								<option className="selectc" value="">
								{t("userp5")}
								</option>
								<option value="WEEKLY">{t("userweek")}</option>
								<option value="MONTHLY">{t("usermonth")}</option>
							</select>
						</span>
						<span className="users_drop">
							<input
								placeholder={t("userp6")}
								onChange={optionsFund}
								name="amount"
								type="number"
								min={
									pack == "WEEKLY"
										? "3000"
										: pack == "MONTHLY"
										? "30000"
										: "3000"
								}
								max={
									pack == "WEEKLY"
										? "5000"
										: pack == "MONTHLY"
										? "100000"
										: "5000"
								}
							/>
						</span>
						<span className="users_drop">
							<select value={pmethod} onChange={optionsFund} name="method">
								<option value="">{t("userp7")}</option>
								<option value="BITCOIN">BITCOIN</option>
								<option value="ETHEREUM">ETHEREUM</option>
								<option value="USDT">USDT</option>
							</select>
						</span>
						<span className="users_drop user_submit">
							<input type="submit" value={t("Submit")} />
						</span>
					</form>
				</div>
			)}
		</>
	);
}

export default UserPension;
