import React, { useEffect, useState } from "react";
import Navigation from "./Navigation";
import StickyMenu from "./lib/StickyMenu";
import logo from "./logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Header({ action }) {
	const [lang, setlang] = useState(localStorage.getItem("lang"));
	useEffect(() => {
		StickyMenu();
	}, []);
	const { t, i18n } = useTranslation();
	const optionsState = (e) => {
		const { name, value } = e.target;
		if (name == "language") {
			setlang(value);
			i18n.changeLanguage(value);
			localStorage.setItem("lang", value);
		}
	};
	return (
		<header className="appie-header-area appie-sticky">
			<div className="container">
				<div className="header-nav-box">
					<div className="row align-items-center">
						<div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
							<div className="appie-logo-box">
								<Link to="/">
									<img src={logo} alt="logo" width={"40px"} />
									<span>seamridge</span>
								</Link>
							</div>
						</div>
						<div className="col-lg-5 col-md-1 col-sm-1 order-3 order-sm-2">
							<div className="appie-header-main-menu">
								<Navigation />
							</div>
						</div>
						<div className="col-lg-5  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
							<div className="appie-btn-box text-right">
								<span className="navlang">
									<select name="language" value={lang} onChange={optionsState}>
										<option value={"en"}>EN</option>
										<option value={"de"}>DE</option>
										<option value={"it"}>IT</option>
										<option value={"es"}>ES</option>
									</select>
								</span>
								<a className="login-btn" href="/signin">
									<i className="fal fa-user" />{" "}
									<span className="loginTxt">{t("footL11")}</span>
								</a>
								<a className="main-btn ml-30" href="/signup">
									{t("footL10")}
								</a>
								<div
									onClick={(e) => action(e)}
									className="toggle-btn ml-30 canvas_open d-lg-none d-block"
								>
									<i className="fa fa-bars" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}

export default Header;
