import React, { useState, useMemo, useEffect } from "react";
import Footer from "../../Footer/Footer";
import { Navigate, Link } from "react-router-dom";
import "../css/reg.css";
import Select from "react-select";
import countryList from "react-select-country-list";
import Nav from "../../Nav/Nav";
import { useTranslation } from "react-i18next";
import img from "./img.png";
function Login() {
	const { t } = useTranslation();
	const [name, setName] = useState(null);
	const [email, setEmail] = useState(null);
	const [redirctTo, setRedirctTo] = useState(false);
	const [log, setLog] = useState("");
	const [message, setMessage] = useState(null);
	const [check, setCheck] = useState(null);
	const [eye, seteye] = useState(true);
	const [password, setpassword] = useState("password");
	const [spassword, setspassword] = useState("password");
	const [type, settype] = useState(true);
	const [value, setValue] = useState("");
	const options = useMemo(() => countryList().getData(), []);

	useEffect(() => {
		if (localStorage.getItem("SeamR_user")) {
			setRedirctTo(true);
		}
	}, []);

	const handleInputChange = (e) => {
		const { name, value, type } = e.target;
		if (name == "name") {
			setName(value);
		}
		if (name == "email") {
			setEmail(value);
		}
		if (name == "message") {
			setMessage(value);
		}
		if (name == "password") {
			setpassword(value);
		}
	};
	const onSubmit = async (e) => {
		e.preventDefault();
		let result = await fetch(
			"https://seamridge.com/backend/public/api/login",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify({ email, password }),
			}
		);
		result = await result.json();
		if (result.token) {
			localStorage.setItem("SeamR_user", JSON.stringify(result));
			setRedirctTo(true);
		} else {
			localStorage.setItem("SeamR_error", JSON.stringify(result));
			setLog(result.message);
		}
		e.target[1].value = "";
	};
	const Eye = () => {
		seteye(!eye);
	};
	const changeHandler = (value) => {
		setValue(value);
	};
	const Type = () => {
		settype(!type);
	};
	if (redirctTo) {
		return <Navigate to="/app" />;
	}

	return (
		<>
			<Nav />
			<section className="contact-section">
				<div className="container">
					<div className="row">
						<div className="col-md-6 contactf">
							<div className="contact-form reg-form">
								<h1>{t("loginT")}</h1>
								<p>{t("loginST")}</p>
								<form onSubmit={onSubmit} method="post" className="row">
									<div className="col-md-10">
										{t("email")}
										<input
											type="email"
											name="email"
											placeholder={t("emailText")}
											onChange={(e) => handleInputChange(e)}
										/>
									</div>

									{/* <div className="col-md-10">
										<input
											type={password}
											className="warning"
											placeholder="Enter your password"
											value={password}
											onChange={(e) => handleInputChange(e)}
											name="password"
										/>
										
									</div> */}
									<div className="col-md-10">
										{t("password")}
										<input
											type={eye ? "password" : "text"}
											name="password"
											placeholder={t("passwordText")}
											onChange={(e) => handleInputChange(e)}
										/>
										<i
											onClick={Eye}
											className={`fa form_eye ${
												eye ? "fa-eye-slash" : "fa-eye"
											}`}
										></i>
									</div>
									{{ log } && (
										<div className="container">
											<h5
												style={{
													color: "red",
													paddingBottom: "20px",
													width: "94%",
													fontWeight: "bold",
												}}
											>
												<br />
												{log}
											</h5>
										</div>
									)}
									<div className="col-md-10 log_check">
										<div>
											<input type="checkbox" /> {t("remem")}
										</div>
										<div className="log_forgot">
											<Link to="/forgot">{t("forgot")}</Link>
										</div>
									</div>
									<div className="contact-btn logBtn">
										<input
											type="submit"
											name="submit"
											className="col-md-10"
											value={t("login")}
										/>
									</div>
									<div className="orDiv col-md-10">
										<span className="rightLn"></span>
										<span className="midtxt">{t("or")}</span>
										<span className="leftLn"></span>
									</div>
									<div className="contact-btn logBtn">
										<input
											type="submit"
											name="submit"
											className="col-md-10 contact-white"
											value={t("HSignup")}
											onClick={(event) => (window.location.href = "/signup")}
										/>
									</div>
								</form>
							</div>
						</div>
						<div className="col-md-6 authwindView">
							<div className="authimg">
								<img src={img} alt="Img" width={"100%"} />
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Login;
