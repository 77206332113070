import React, { useEffect, useState } from "react";
import heroThumbOne from "./hero-thumb-1.png";
import trustOne from "./trust1.png";
import trustTwo from "./trust2.png";
import trustThree from "./trust3.png";
import trustFour from "./trust4.png";
import trustSix from "./trust6.png";
import trustSeven from "./trust7.png";
import trustEight from "./trust8.png";
import Heroeng from "./Hero.mp4";
import Heroger from "./Hero1.mp4";
import Herospa from "./Hero2.mp4";
import Heroita from "./Hero3.mp4";
import Eng from "./Eng.gif";
import Spa from "./Spa.gif";
import Ita from "./Ita.gif";
import Ger from "./Ger.gif";
import gif from "./Gif.gif";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import "video-react/dist/video-react.css"; // import css
import { Player, BigPlayButton } from "video-react";

function Hero() {
	const { t } = useTranslation();
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);
	return (
		<>
			<section className="appie-hero-area">
				<div className="container">
					<div className="container_bg">
						<div className="row align-items-center" data-aos="fade-left">
							<div className="col-lg-6">
								<div className="appie-hero-content">
									<br />
									<h1 className="appie-title">{t("Htitle")}</h1>
									<p className="pbody">{t("HsubTitle")}</p>
									<ul>
										<li>
											<a href="/signin">{t("Hbtn")}</a>
										</li>
									</ul>
									<div className="lowerDk">
										<p>{t("Htrusted")}</p>
										<br />
										<div className="trustImgList">
											<img src={trustThree} alt="Binance" width={"100px"} />
											<img src={trustEight} alt="crypto" width={"100px"} />
											<img src={trustOne} alt="coinbase" width={"100px"} />
											<img src={trustSix} alt="sequoia" width={"100px"} />		
											<img src={trustSeven} alt="blockchain" width={"150px"} />
											<img src={trustTwo} alt="opensea" width={"100px"} />
										</div>
									</div>
									<br />
								</div>
							</div>
							<div className="col-lg-6">
								<div className="appie-hero-thumb">
									<div
										className="thumb wow animated fadeInUp"
										data-wow-duration="2000ms"
										data-wow-delay="200ms"
										id="HeroVid"
									>
										<img src={gif} alt="gif" className="gifbanner" />

										{/* <Player
											poster={
												t("lang") == "en"
													? Eng
													: t("lang") == "de"
													? Ger
													: t("lang") == "it"
													? Ita
													: t("lang") == "es" && Spa
											}
											src={t("lang") == "en"
											? Heroeng
											: t("lang") == "de"
											? Heroger
											: t("lang") == "it"
											? Heroita
											: t("lang") == "es" && Herospa}
										>
											<BigPlayButton position="center" />
										</Player> */}
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Hero;
