import React, { useEffect, useState } from "react";
import "../css/fund.css";
import sucess from "./cryp/sucess.png";
import axios from "axios";
import { useTranslation } from "react-i18next";
function UserFund() {
	const { t } = useTranslation();
	const [pack, setpack] = useState("");
	const [pmethod, setpmethod] = useState("");
	const [amount, setamount] = useState("");
	const [formE, setformE] = useState(false);
	const [formNext, setformNext] = useState(false);
	const [userplan, setuserplan] = useState("");
	const [token, settoken] = useState("");
	const [userupgrade, setuserupgrade] = useState(false);
	const [usersbal, setusersbal] = useState("");
	const [usersname, setusersname] = useState("");
	const [email, setemail] = useState("");
	//Life Cycle Users
	useEffect(() => {
		if (localStorage.getItem("SeamR_user")) {
			settoken(JSON.parse(localStorage.getItem("SeamR_user")).token);
			setusersname(JSON.parse(localStorage.getItem("SeamR_user")).user.name);
			setemail(JSON.parse(localStorage.getItem("SeamR_user")).user.email);
		}
	}, []);
	//End LF
	const optionsFund = (e) => {
		const { name, value } = e.target;
		if (name == "package") {
			setpack(value);
		}
		if (name == "method") {
			setpmethod(value);
		}
		if (name == "amount") {
			setamount(value);
		}
	};
	const onSubmit = (e) => {
		e.preventDefault();
		//Rules
		if (
			pack == "" ||
			amount == "" ||
			pmethod == "" ||
			(pack && amount && pmethod == "")
		) {
			setformE(true);
			setTimeout(() => {
				setformE(false);
			}, 2000);
		} else {
			setformE(false);
			setformNext(true);
			let result = {
				name: "fund",
				slug: pack,
				description: "Deposit",
				price: amount,
				username: `${usersname} with Email:${email} Requests using ${pmethod}`,
				usertoken: token,
				accept: 0,
			};
			let invest = {
				name: "invest",
				slug: pack,
				description: "Investment Fund",
				price: amount,
				username: usersname,
				usertoken: token,
				accept: 0,
			};
			let upgraded = {
				name: "fund",
				slug: pack,
				description: "Profit Added",
				price: usersbal.profit,
				username: `${usersname} Requests ${pmethod}`,
				usertoken: token,
				accept: 0,
			};
			let upgraded_reset = {
				slug: pack,
			};
			let axiosConfig = {
				headers: {
					"Content-Type": "application/json;charset=UTF-8",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
			};
			if (!userupgrade) {
				axios
					.all([
						axios.post(
							"https://seamridge.com/backend/public/api/products",
							result,
							axiosConfig
						),
						axios.post(
							"https://seamridge.com/backend/public/api/products",
							invest,
							axiosConfig
						),
					])
					.then(axios.spread((data1, data2) => {}))
					.catch((err) => {});
			} else {
				axios
					.all([
						axios.post(
							"https://seamridge.com/backend/public/api/products",
							result,
							axiosConfig
						),
						axios.post(
							"https://seamridge.com/backend/public/api/products",
							invest,
							axiosConfig
						),
						axios.post(
							"https://seamridge.com/backend/public/api/products",
							upgraded,
							axiosConfig
						),
						axios.put(
							`https://seamridge.com/backend/public/api/products/${usersbal.resetid}`,
							upgraded_reset,
							axiosConfig
						),
					])
					.then(axios.spread((data1, data2) => {}))
					.catch((err) => {});
			}
		}
		if (localStorage.getItem("SeamR_pushedToDatabase")) {
			localStorage.removeItem("SeamR_pushedToDatabase");
		}
	};
	const onuserupgrade = () => {
		setuserupgrade(!userupgrade);
	};
	//Life Cycle
	useEffect(() => {
		if (localStorage.getItem("SeamR_plan")) {
			setuserplan(JSON.parse(localStorage.getItem("SeamR_plan")));
		}
		if (localStorage.getItem("SeamR_users")) {
			setusersbal(JSON.parse(localStorage.getItem("SeamR_users")));
		}
	}, []);
	//new image gen
	const btcAddress = "bc1qud4wulgwqh08yle34mj2fk73zta2tnaj2p6k2p";
	const ethAddress = "0xdeB77e57e84DEc66E4Fa2abe68835dE97ad42b1d";
	const usdtAddress = "TGvMcSVGRfj5nrFXTtapXswV58qXDMK6Ct";

	const getAddress = (pmethod) => {
		switch (pmethod) {
			case "BITCOIN":
				return btcAddress;
			case "ETHEREUM":
				return ethAddress;
			case "USDT":
				return usdtAddress;
			default:
				return "";
		}
	};

	const address = getAddress(pmethod);
	const qrCodeUrl = `https://qrcode.tec-it.com/API/QRCode?size=medium&dpi=120&data=${address}&choe=UTF-8`;

	//End LF
	const usertotal = usersbal ? usersbal.total + usersbal.profit : 0;
	const amountcondition =
		usertotal > 0 && pack == "PREMIUM"
			? 15000 - usertotal
			: pack == "PRO" && 70000 - usertotal;
	return (
		<>
			{formNext ? (
				<div>
					{pmethod == "BITCOIN" ||
					pmethod == "ETHEREUM" ||
					pmethod == "USDT" ? (
						<>
							<div className="user_fund">
								<p>{t("userf7")}</p>
								<h6>
									{t("userf8")} {pmethod} {t("userf9")}
								</h6>
								<img
									className="cryp_img"
									src={qrCodeUrl}
									alt="crypto"
									style={{ width: "300px" }}
								/>
							</div>
							<div className="user_fundbtm">
								<p>{t("userf10")}</p>
								<h6>
									{pmethod == "BITCOIN"
										? "bc1qud4wulgwqh08yle34mj2 fk73zta2tnaj2p6k2p"
										: pmethod == "ETHEREUM"
										? "0xdeB77e57e84DEc66E4Fa2ab e68835dE97ad42b1d"
										: pmethod == "USDT" &&
										  "TGvMcSVGRfj5nrFXTtapXs wV58qXDMK6Ct"}
								</h6>
							</div>
							<div className="user_fundbtm">
								<p>{t("userf11")}</p>
								<h6>{pmethod}</h6>
							</div>
							<span className="users_btn">
								<a href="/app">{t("back")}</a>
							</span>
						</>
					) : (
						<>
							<div className="user_fund">
								<p>{t("userf12")}</p>
								<h6>
									{t("userf13")} <b>{pmethod}</b> {t("userf14")}
								</h6>
								<br />
								<img className="cryp_img" src={sucess} alt="sucess" />
								<br />
								<h6>
									{t("userf15")} <b>{pmethod}</b> {t("userf16")}
								</h6>
							</div>
							<br />
							<span className="users_btn">
								<a href="/app">{t("back")}</a>
							</span>
						</>
					)}
				</div>
			) : (
				<div className="user_fund">
					<p>{userupgrade ? t("userf6") : t("userf2")}</p>
					<h3>{userupgrade ? "$ " + usertotal.toLocaleString() : "$ 00.00"}</h3>

					{pack == "BASIC" ? (
						<p className="abso">
							{t("IBTitle")} {t("from")} $100 - $9,999{" "}
							{userplan !== pack &&
								userplan !== "PREMIUM" &&
								userplan !== "PRO" && (
									<button className="plan_up" onClick={onuserupgrade}>
										{!userupgrade ? t("upgrade") : t("cancel")}
									</button>
								)}
						</p>
					) : pack == "PREMIUM" ? (
						<p className="abso">
							{t("IPreTitle")} {t("from")} $10,000 - $99,999{" "}
							{userplan !== pack && userplan !== "PRO" && (
								<button className="plan_up" onClick={onuserupgrade}>
									{!userupgrade ? t("upgrade") : t("cancel")}
								</button>
							)}
						</p>
					) : (
						pack == "PRO" && (
							<p className="abso">
								{t("IProTitle")} {t("from")} $100,000+{" "}
								{userplan !== pack && (
									<button className="plan_up" onClick={onuserupgrade}>
										{!userupgrade ? t("upgrade") : t("cancel")}
									</button>
								)}
							</p>
						)
					)}
					{formE && <p className="abso color_red">{t("complete")}</p>}
					<form onSubmit={onSubmit}>
						<span className="users_drop">
							<select value={pack} onChange={optionsFund} name="package">
								<option className="selectc" value="">
									{t("userf3")}
								</option>
								<option value="BASIC">{t("IBTitle")}</option>
								<option value="PREMIUM">{t("IPreTitle")}</option>
								<option value="PRO">{t("IProTitle")}</option>
							</select>
						</span>
						<span className="users_drop">
							<input
								placeholder={t("userf4")}
								onChange={optionsFund}
								name="amount"
								type="number"
								min={
									userupgrade
										? amountcondition
										: pack == "BASIC"
										? "100"
										: pack == "PREMIUM"
										? "10000"
										: pack == "PRO"
										? "100000"
										: "100"
								}
								max={
									pack == "BASIC"
										? "9999"
										: pack == "PREMIUM"
										? "99999"
										: pack == "PRO"
										? "100000000"
										: "9999"
								}
							/>
						</span>
						<span className="users_drop">
							<select value={pmethod} onChange={optionsFund} name="method">
								<option value="">{t("userf5")}</option>
								<option value="BITCOIN">BITCOIN</option>
								<option value="ETHEREUM">ETHEREUM</option>
								<option value="USDT">USDT</option>
							</select>
						</span>
						<span className="users_drop user_submit">
							<input type="submit" value={t("Submit")} />
						</span>
					</form>
				</div>
			)}
		</>
	);
}

export default UserFund;
