import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
export default function SimpleSlider() {
	const { t } = useTranslation();
	const settings = {
		centerMode: false,
		draggable: false,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 3000,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
	};
	return (
		<div className="container mobile">
			<Slider {...settings}>
				<div>
					<div className="pricing-one__single" id="plans">
						<div className="pricig-heading">
							<h6>{t("IBTitle")}</h6>
							<div className="price-range">
								<span>$100 - $9,999</span>
							</div>
							<div className="pricing-btn mt-35">
								<a className="table-btn" href="/signin">
									{t("IBtn")}
								</a>
								<div className="table-hr"></div>
							</div>
						</div>
						<div className="pricig-body">
							<ul>
								<li>
									<i className="fal fa-check" /> {t("Ii1")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("IB")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("Ii2")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("Ii3")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("Ii4")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("IBP1")}
								</li>
								<li className="v_opa_none">
									<i className="fal fa-check" /> 24 hours support
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div>
					<div className="pricing-one__single">
						<div className="pricig-heading">
							<h6>{t("IPreTitle")}</h6>
							<div className="price-range">
								<span>$10,000 - $99,999</span>
							</div>
							<div className="pricing-btn mt-35">
								<a className="table-btn" href="/signin">
									{t("IBtn")}
								</a>
								<div className="table-hr"></div>
							</div>
						</div>
						<div className="pricig-body">
							<ul>
								<li>
									<i className="fal fa-check" /> {t("Ii1")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("IPre")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("Ii2")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("Ii3")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("Ii4")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("IBP1")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("IPP1")}
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div>
					<div className="pricing-one__single">
						<div className="pricig-heading">
							<h6>{t("IProTitle")}</h6>
							<div className="price-range">
								<span>$100,000+</span>
							</div>
							<div className="pricing-btn mt-35">
								<a className="table-btn" href="/signin">
									{t("IBtn")}
								</a>
								<div className="table-hr"></div>
							</div>
						</div>
						<div className="pricig-body">
							<ul>
								<li>
									<i className="fal fa-check" /> {t("Ii1")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("IPro")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("Ii2")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("Ii3")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("Ii4")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("IPro1")}
								</li>
								<li>
									<i className="fal fa-check" /> {t("IPP1")}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</Slider>
		</div>
	);
}
